import { z } from 'zod';

export const notificationWidgetTypes = z.enum(['one_time_promo'])

export type NotificationWidgetType = z.infer<typeof notificationWidgetTypes>

export const oneTimePromoWidgetOptionsSchema = z.object({
  promo_id: z.string(),
  valid_from: z.string().datetime().optional(),
  valid_to: z.string().datetime().optional(),
  description: z.string(),
})

export type OneTimePromoOptionsWidget = z.infer<typeof oneTimePromoWidgetOptionsSchema>

export const notificationWidgetSchema = z.object({
  type: z.literal('one_time_promo'),
  options: oneTimePromoWidgetOptionsSchema,
})

export type NotificationWidget = z.infer<typeof notificationWidgetSchema>

export const UserOneTimePromoWidgetOptionsSchema = oneTimePromoWidgetOptionsSchema.extend({used: z.boolean()})
export type UserOneTimePromoOptionsWidget = z.infer<typeof UserOneTimePromoWidgetOptionsSchema>
export const userNotificationWidgetSchema = z.object({
  type: z.literal('one_time_promo'),
  options: UserOneTimePromoWidgetOptionsSchema,
})
export type UserNotificationWidget = z.infer<typeof userNotificationWidgetSchema>
