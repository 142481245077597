import { Suspense } from 'react';
import Wrapper from '../../Wrapper';
import CustomersNumberTrend from '../CustomersNumberTrend';
import AllocatedPointsTrend from '../AllocatedPointsTrend';
import MainActivityHours from '../MainActivityHours';
import GoalsRank from '../GoalsRank';

interface LoyaltyProgramTabProps {
}

const LoyaltyProgramTab = ({selectedSalesPointId}: {selectedSalesPointId: string}) => {
  return (
    <div className={'grid-cols-1 grid gap-y-[50px] gap-x-7 lg:grid-cols-2'}>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <CustomersNumberTrend />
      </Suspense>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <AllocatedPointsTrend selectedSalesPointId={selectedSalesPointId} />
      </Suspense>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <MainActivityHours selectedSalesPointId={selectedSalesPointId} />
      </Suspense>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <GoalsRank selectedSalesPointId={selectedSalesPointId} />
      </Suspense>
    </div>
  );
};
export default LoyaltyProgramTab;
