import { createFileRoute, redirect } from '@tanstack/react-router';
import PageLoader from '../../../../microcomponents/PageLoader';
import Wrapper from '../../../../components/Wrapper';
import { Button, Flex, Form, InputNumber, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { CustomerQrCodeType } from '@next-is-free-monorepo/next-is-free-types';
import { checkCustomerQrCode } from '@next-is-free-monorepo/next-is-free-shared-utils';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { salesPointsQueryOptions } from '../../../../utils/queryOptions';
import { apiV1 } from '../../../../utils/apiClient';
import { z } from 'zod';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import { AnimatePresence, motion } from 'framer-motion';
import { COLORS } from '@next-is-free-monorepo/globals';

export const Route = createFileRoute('/_authenticated/transactions/capture')({
  component: RouteComponent,
  pendingComponent: PageLoader,
/*  loader: (context)=>{
    if(!context.context.isVisible){
      throw redirect({to: '/transactions'})
    }
  }*/
});

function RouteComponent() {

  const [input, setInput] = useState('');
  const [isFocused, setIsFocused] = useState(true);
  const [qrCode, setQrCode] = useState<{ type: CustomerQrCodeType, userId: string, clean: string } | undefined>(undefined);
  const [selectedSalesPointId, setSelectedSalesPointId] = useState('');
  const [feedback, setFeedback] = useState<undefined | {
    type: 'success' | 'error',
    title: string,
    description: string
  }>(undefined);
  const [changeSalesPoint, setChangeSalesPoint] = useState(false);

  const salesPoints = useSuspenseQuery(salesPointsQueryOptions());

  const salesPointsOptions = salesPoints.data.map((point) => ({
    value: point.id,
    label: point.name
  }));

  const [form] = useForm();


  useEffect(() => {
    const savedSalesPointId = localStorage.getItem('selectedSalesPointId');
    if (savedSalesPointId) {
      setSelectedSalesPointId(savedSalesPointId);
    } else {
      setSelectedSalesPointId(salesPoints.data[0].id);
    }

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    // Add event listeners for focus and blur
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    const handleKeyDown = (event: any) => {
      if (qrCode || feedback) {
        return;
      }
      if (event.key === 'Enter') {
        // il micro timeout serve per evitare il problema di validazione dell'input
        setTimeout(() => {
          checkCustomerQrCode(input).then((qrCodeType) => {
            setQrCode(qrCodeType);
            if(qrCodeType.type === 'goal'){
              redeemPoints.mutate({code: qrCodeType.clean});
            }else if(qrCodeType.type === 'oneTimePromo'){
              redeemOneTimePromo.mutate({code: qrCodeType.clean});
            }
          }).catch((e) => {
            setFeedback({ type: 'error', title: 'Errore', description: 'Codice QR non valido' });
          });
        }, 20);


        setInput(''); // Clear the input for the next scan
      } else if (event.key.length === 1 && !event.ctrlKey && !event.metaKey) {
        // Append the key to the input string
        setInput((prev) => prev + event.key);
      }
    };

    // Attach the keydown listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [input, qrCode, feedback]);

  const addPoints = useMutation({
    mutationKey: ['addPoints', qrCode?.userId],
    mutationFn: async (variables: { amount: string }) => {
      const cleanAmount = await z.coerce.number().parseAsync(variables.amount);
      return await apiV1.post('/shop/transactions/add-points', {
        amount: cleanAmount,
        user_id: qrCode?.userId,
        sales_point_id: selectedSalesPointId
      });
    },
    onSuccess: (data) => {
      setFeedback({ type: 'success', title: 'Successo', description: `Sono stati aggiunti ${data.details} punti` });
    },
    onError: (e) => {
      console.log(e);
      setFeedback({ type: 'error', title: 'Errore', description: 'Errore durante l\'aggiunta dei punti' });
    }
  });

  const redeemPoints = useMutation({
    mutationKey: ['redeemPoints', qrCode?.userId],
    mutationFn: async (variables: { code: string }) => await apiV1.post('/shop/transactions/redeem-points', {
      sales_point_id: selectedSalesPointId,
      qr_code: variables.code,
    }),
    onError: (e) => {
      setFeedback({ type: 'error', title: 'Errore', description: 'Qr code non valido' });
    },
    onSuccess: (data) => {
      setFeedback({ type: 'success', title: 'Successo', description: `Sono stati sottratti ${data.details} punti` });
    }
  });

  const redeemOneTimePromo = useMutation({
    mutationKey: ['redeemOneTimePromo', qrCode?.userId],
    mutationFn: async (variables: { code: string }) => await apiV1.post('/shop/one-time-promos/redeem', {
      sales_point_id: selectedSalesPointId,
      qr_code: variables.code,
      sales_point_name: salesPoints.data.find((point) => point.id === selectedSalesPointId)?.name
    }),
    onError: (e) => {
      setFeedback({ type: 'error', title: 'Errore', description: 'Qr code non valido' });
    },
    onSuccess: (data) => {
      setFeedback({ type: 'success', title: 'Successo', description: `Buono sconto Valido` });
  }});

  function reset() {
    setQrCode(undefined);
    setFeedback(undefined);
    form.resetFields();
  }

  async function saveSalesPointPreference(salesPointId: string) {
    setSelectedSalesPointId(salesPointId);
    localStorage.setItem('selectedSalesPointId', salesPointId);
    message.success('Preferenza salvata');
    setChangeSalesPoint(false)
  }

  return (
    <div className={'container'}>
      <Flex vertical justify={'center'} style={{ minHeight: '80vh' }}>
        <Wrapper style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AnimatePresence initial={false} mode={'popLayout'}>
            {!qrCode && !feedback &&
              <motion.div initial={{ y: -300, opacity: 0 }} animate={{ y: 0, opacity: 1 }} key={1}
                          exit={{ y: -300, opacity: 0 }}>
                <Flex vertical gap={20} align={'center'}>
                  <h2>Scansiona il Qr Code</h2>
                  <img width={100} height={100} src={'/barcode-scanner.png'} />
                  {isFocused ?
                    <Button
                      icon={<div style={{ width: 10, height: 10, backgroundColor: 'green', borderRadius: 20 }} />}>
                      Pronto
                    </Button> :
                    <Button danger
                            icon={<div style={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: 20 }} />}>
                      Clicca qui
                    </Button>
                  }
                  {salesPoints.data.length > 0 &&
                    <>{changeSalesPoint ?
                      <div>
                        <Select style={{ width: 200 }} options={salesPointsOptions} value={selectedSalesPointId}
                                onSelect={saveSalesPointPreference} />
                      </div>
                      :
                      <div style={{ textAlign: 'center' }}>
                        <p style={{ color: COLORS.gray }}>Punto vendita:</p>
                        <Flex gap={8} align={'center'} justify={'center'}>
                          <strong>{salesPoints.data.find((point) => point.id === selectedSalesPointId)?.name}</strong>
                          <a type={'text'} onClick={()=>setChangeSalesPoint(true)}>Cambia</a>
                        </Flex>
                      </div>
                      }
                    </>
                  }

                </Flex>
              </motion.div>
            }
            {qrCode?.type === 'addPoints' && !feedback &&
              <motion.div key={2} initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -300, opacity: 0 }}>
                <Button onClick={() => reset()} style={{ color: 'grey', marginBottom: 30 }}
                        icon={<ArrowLeftOutlined />}>Indietro</Button>
                <Flex vertical gap={20} align={'center'}>
                  <h2>Inserisci l'importo</h2>
                  <Form
                    form={form}
                    onFinish={addPoints.mutate}
                    layout={'vertical'}
                    initialValues={{}}
                  >
                    <Form.Item
                      name="amount"
                      label={'Importo'}
                      required={false}
                      rules={[{ required: true, message: 'Inserisci un importo valido' }]}
                    >
                      <InputNumber
                        disabled={addPoints.isPending}
                        style={{ minWidth: 250 }}
                        autoFocus={true}
                        size={'large'}
                        suffix={'€'}
                        placeholder={'es. 10,00'}
                        parser={value => value?.replace(/,/g, '.') || ''}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        loading={addPoints.isPending}
                        icon={<ArrowRightOutlined />}
                        style={{ width: 250, marginTop: 10 }}
                        type={'primary'}
                        size={'large'}
                        htmlType="submit"
                        iconPosition={'end'}
                      >
                        Conferma
                      </Button>
                    </Form.Item>
                  </Form>
                </Flex>
              </motion.div>
            }
            {feedback &&
              <motion.div key={3} initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 200, opacity: 0 }}>
                <Flex vertical gap={20} align={'center'}>
                  {feedback.type === 'success' ?
                    <Flex align={'center'} justify={'center'}
                          style={{ width: 100, height: 100, borderRadius: 100, backgroundColor: 'green' }}>
                      <IconCheck color={'white'} width={30} height={30} />
                    </Flex> :
                    <Flex align={'center'} justify={'center'}
                          style={{ width: 100, height: 100, borderRadius: 100, backgroundColor: 'red' }}>
                      <IconX color={'white'} width={30} height={30} />
                    </Flex>
                  }
                  <div style={{ textAlign: 'center' }}>
                    <h2>{feedback.title}</h2>
                    <p>{feedback.description}</p>
                  </div>
                  <motion.div
                    initial={{ width: '100%' }}
                    animate={{ width: '0%' }}
                    transition={{ duration: 2, ease: 'linear' }}
                    onAnimationComplete={reset}
                    style={{ height: 5, backgroundColor: 'black', marginTop: 10, borderRadius: 10 }}
                  />
                </Flex>
              </motion.div>
            }
          </AnimatePresence>
        </Wrapper>
      </Flex>
    </div>);
}
