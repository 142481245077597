import { ReactNode } from 'react';
import { color } from 'framer-motion';

interface BgIconProps {
  icon: ReactNode;
  color: 'blue' | 'green' | 'red' | 'black';
}

const BgIcon = ({ icon, color }: BgIconProps) => {

  const colors = {
    blue: { main: 'bg-blue-700', hover: 'hover:bg-blue-700', light: 'bg-blue-100', text: 'text-blue-600' },
    red: { main: 'bg-red-600', hover: 'hover:bg-red-700', light: 'bg-red-100', text: 'text-red-600' },
    green: { main: 'bg-green-700', hover: 'hover:bg-green-700', light: 'bg-green-100', text: 'text-green-600' },
    black: { main: 'bg-gray-900', hover: 'hover:bg-gray-700', light: 'bg-gray-100', text: 'text-gray-800' }
  };

  return (<div
    className={`${colors[color].light} ${colors[color].text} w-8 h-8 rounded flex justify-center items-center p-[6px]`}>
    {icon}
  </div>);
};
export default BgIcon;
