import { Select } from 'antd';
import { useSuspenseQuery } from '@tanstack/react-query';
import { staffQueryOptions } from '../utils/queryOptions';
import { UserSwitchOutlined } from '@ant-design/icons';
import { HugeiconsIcon } from '@hugeicons/react';
import { User02Icon, UserMultiple02FreeIcons, UserMultipleFreeIcons } from '@hugeicons/core-free-icons';

interface SalesPointsSelectProps {
  setSelectedStaffMemberId: (value: string) => void;
  selectedStaffMemberId: string;
  size? : 'small' | 'middle' | 'large';
}

const StaffMemberSelect = ({ setSelectedStaffMemberId, selectedStaffMemberId, size = 'middle' }: SalesPointsSelectProps) => {

  const staff = useSuspenseQuery(staffQueryOptions());

  let staffOptions = staff.data.map((member) => ({
    value: member.id,
    label: member.display_name
  }));

  staffOptions = [...staffOptions, { value: '', label: 'Tutti' }];

  if(staffOptions.length === 1){
    return <></>
  }

  return (<Select prefix={<HugeiconsIcon icon={UserMultiple02FreeIcons} size={16}/>} size={size} style={{ width: 200, flex: 1 }}
                  placeholder={'Staff'}
                  options={staffOptions}
                  onSelect={setSelectedStaffMemberId}
                  value={selectedStaffMemberId} />);
};
export default StaffMemberSelect;
