import { IconX } from '@tabler/icons-react';
import { Skeleton, Tag } from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import {
  Notification, notificationsStatusColors, notificationsStatusLabels, NotificationDetails as NotificationDetailsType
} from '@next-is-free-monorepo/next-is-free-types';
import { useQuery } from '@tanstack/react-query';
import { apiV1 } from '../../utils/apiClient';

interface NotificationDetailsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  notification: Notification;
}


const NotificationDetails = ({ open, setOpen, notification }: NotificationDetailsProps) => {

  /*  const notificationReads = useQuery({
      queryKey: ['notification-read', notification.id],
      queryFn: async () => await apiV1.get<string[]>(`/shop/notifications/read?notification_id=${notification.id}`),
      enabled: !!notification && open
    });

    const groupedByHours: {[key in Hour]: string[]} = groupByHours(notificationReads.data || []);

    function getHighestCount(obj: { [key in Hour]: string[] }): number {
      const occurrenceMap: Record<string, number> = {};

      // Count occurrences of each string
      for (const hour in obj) {
        const hourKey = hour as Hour;
        for (const value of obj[hourKey]) {
          occurrenceMap[value] = (occurrenceMap[value] || 0) + 1;
        }
      }

      // Return the highest count
      return Math.max(...Object.values(occurrenceMap), 0);
    }

    const highestOccurrence = getHighestCount(groupedByHours);*/

  const notificationDetails = useQuery({
    queryKey: ['notification-details', notification.id],
    queryFn: async () => await apiV1.get<NotificationDetailsType>(`/shop/notifications/${notification.id}/details`),
    enabled: !!notification && open
  });

  return (
    <div
      className={`flex flex-col transition-all gap-5 border-l-[1px] lg:max-w-[350px] overflow-hidden ${open ? 'w-full md:w-[300px] flex-1 md:min-w-[300px]' : 'w-0'} top-0 fixed h-[100vh] md:h-auto z-[999] bg-white overscroll-none md:static`}>
      <div
        className={'flex items-center justify-between h-[50px] p-3 w-full border-b-[1px] border-b-slate-200'}>
        <p className={'text-lg font-semibold'}>
          Dettagli
        </p>
        <div className={'flex items-center gap-2 cursor-pointer text-slate-500'}>
          <IconX onClick={() => setOpen(false)} />
        </div>
      </div>
      <div className={'p-3 overflow-y-scroll pb-10 scrollbar-thin scrollbar-track-white scrollbar-thumb-gray-400'}>
        <p className={'font-bold text-md'}>Info</p>
        <div className={'grid grid-cols-2'}>
          <p>Stato</p>
          <Tag
            className={`${notificationsStatusColors[notification.status].bg} ${notificationsStatusColors[notification.status].text} justify-self-end m-0 rounded-3xl`}
            bordered={false}>{notificationsStatusLabels[notification.status]}</Tag>
          <p>Programmata per</p>
          <p
            className={'text-right'}>{notification?.schedule_time ? dayjs(notification.schedule_time).format('DD/MM/YY - HH:mm') : '-'}</p>
          <p>Inviata</p>
          <p
            className={'text-right'}>{notification?.sent_at ? dayjs(notification.sent_at).format('DD/MM/YY - HH:mm') : '-'}</p>
        </div>
        <p className={'font-bold text-md mt-4'}>Interazioni</p>
        {
          notificationDetails.isLoading ?
            <div className={'flex flex-col gap-2'}>
              <Skeleton paragraph={{ rows: 1, width: '100%' }} title={false} active />
              <Skeleton paragraph={{ rows: 1, width: '100%' }} title={false} active />
              <Skeleton paragraph={{ rows: 1, width: '100%' }} title={false} active />
            </div>
            :
            <>
              <div className={'grid grid-cols-2'}>
                <p>Letture</p>
                <p className={'text-right'}>{notificationDetails?.data?.read_count}</p>
                <p>Salvataggi</p>
                <p className={'text-right'}>{notificationDetails?.data?.saved_count}</p>
                <p>Click su call to action</p>
                <p className={'text-right'}>{notificationDetails?.data?.cta_clicks_count}</p>
              </div>
            </>
        }
        {notificationDetails.isSuccess && notificationDetails.data.one_time_promos.map(one_time_promo => (
          <div key={one_time_promo.promo_id}>
            <p className={'font-bold text-md mt-4'}>Buono sconto</p>
            <div className={'grid grid-cols-2'}>
              <p>Valido dal</p>
              <p className={'text-right'}>{one_time_promo?.valid_from ? dayjs(one_time_promo?.valid_from).format('DD/MM/YYYY - HH:mm') : '-'}</p>
              <p>Valido fino al</p>
              <p className={'text-right'}>{one_time_promo?.valid_to ? dayjs(one_time_promo?.valid_to).format('DD/MM/YYYY - HH:mm') : '-'}</p>
              <p>Riscatti</p>
              <p className={'text-right'}>{one_time_promo?.redeemed_count}</p>
            </div>
          </div>
        ))}

        {/*{(notificationDetails?.data?.one_time_promos || []).map(one_time_promo => (<div/>))}*/}
        {/*     {notificationDetails.data && notificationDetails?.data?.one_time_promos?.map(one_time_promo => (<div />))}*/}
        {/* {notificationDetails?.data?.one_time_promos?.map(one_time_promo => (
          <>
            <p className={'font-bold text-md mt-4'}>Buono sconto</p>
            <div className={'grid grid-cols-2'}>
              <p>Valido dal</p>
              <p className={'text-right'}>{one_time_promo?.valid_from ? dayjs(one_time_promo?.valid_from).format('DD/MM/YYYY - HH:mm') : '-'}</p>
              <p>Valido fino al</p>
              <p className={'text-right'}>{one_time_promo?.valid_to ? dayjs(one_time_promo?.valid_to).format('DD/MM/YYYY - HH:mm') : '-'}</p>
              <p>Riscatti</p>
              <p className={'text-right'}>{one_time_promo?.redeemed_count}</p>
            </div>
          </>
        ))}*/}

        {/*  <AnimatePresence>
          {notificationReads?.data && notificationReads?.data.length > 0 &&
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}}>
              <p className={'font-bold text-md mt-4'}>Orari di lettura</p>
              <div className={'w-full grid-cols-[auto,1fr,auto] grid items-center mt-2'}>
                {Object.keys(groupedByHours).map((key) => (
                  <React.Fragment key={key}>
                    <p className={'pr-1 text-slate-500 text-xs'}>
                      {key}
                    </p>
                    <div className={'w-full h-3 bg-slate-100 rounded-3xl flex-1'}>
                      <div className={'w-full h-3 bg-slate-800 rounded-3xl flex-1'}
                           style={{ width: `${(groupedByHours[key as Hour].length / highestOccurrence) * 100}%` }} />
                    </div>
                    <p className={'pl-1 text-right'}>
                      {groupedByHours[key as Hour].length}
                    </p>
                  </React.Fragment>
                ))}
              </div>
            </motion.div>
          }
        </AnimatePresence>*/}
      </div>
    </div>
  );
};
export default NotificationDetails;
