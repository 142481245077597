import { supabase } from './supabase';

export async function signOut() {
  try {
    await supabase.auth.signOut({ scope: 'local' });//scope local is important or the user will be signed out from all devices
    window.location.reload();
  } catch (e) {
    console.error(e);
  }
}
