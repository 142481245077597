import useShopAttrs from '../../utils/hooks/useShopAttrs';
import { NotificationStatus, OneTimePromoOptionsWidget } from '@next-is-free-monorepo/next-is-free-types';
import { IconGift, IconGiftFilled } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { bucketUrl } from '../../utils/urls';

const OneTimePromoWidget = (props: OneTimePromoOptionsWidget & { scale: number, notificationStatus: NotificationStatus }) => {
  const shopAttrs = useShopAttrs();
  const scale = props.scale;
  const defaultImageUrl = `${bucketUrl}${shopAttrs.data.id}/logo.png?${shopAttrs.data.latest_update_at}`;
  const { brand_color } = shopAttrs.data

  type Status = 'not_valid_yet' | 'expired' | 'valid' | 'archived';

  function checkValid(): { status: Status, message: string, badgeColor: string, bg: string } {

    if(props.notificationStatus === 'archived'){
      return { status: 'archived', message: `Archiviato`, badgeColor: '#475569', bg: '#475569' };
    }

    if(!props.valid_from || !props.valid_to) {
      return { status: 'valid', message: `Valido`, badgeColor: brand_color, bg: brand_color };
    }

    const willBeValid = dayjs().isBefore(dayjs(props.valid_from));
    const isExpired = dayjs().isAfter(dayjs(props.valid_to));

    // if the promo is not valid yet
    if (willBeValid) {
      return {
        status: 'not_valid_yet',
        message: `Valido dal ${dayjs(props.valid_from).format('DD/MM/YYYY - HH:mm')}`,
        badgeColor: '#475569', bg: brand_color
      };
    }
    // if the promo is expired
    if (isExpired) {
      return { status: 'expired', message: `Scaduto`, badgeColor: '#475569', bg: '#475569' };
    }
    //if promo is valid
    return { status: 'valid', message: `Valido fino al ${dayjs(props.valid_to).format('DD/MM/YYYY - HH:mm')}`, badgeColor: brand_color, bg: brand_color };
  }

  return (
    <div style={{ padding: 15 * scale, borderRadius: 20 * scale, aspectRatio: 1.5, overflow: 'hidden' }}
         className={'relative bg-blue-100 rounded-md flex-col items-center justify-center flex gap-2 my-4'}>

      <img alt={'shop image'} src={defaultImageUrl} style={{ position: 'absolute', width: '100%' }} />
      <div className={'absolute p-4 flex flex-col justify-between'} style={{
        width: '100%',
        height: '100%',
        background: `linear-gradient(to bottom, ${checkValid().bg}00, ${checkValid().bg} 90%)`
      }}>
        <div className={'flex items-center justify-between'}>
          <div className={'w-[30px] h-[30px] rounded-full flex items-center justify-center'}
               style={{ backgroundColor: checkValid().bg }}>
            <IconGiftFilled size={20} fill={'white'} stroke={'none'} />
          </div>
          <div className={'flex items-center justify-center rounded-3xl text-white px-4 py-0.5'} style={{backgroundColor: checkValid().badgeColor}}>
            <p>{checkValid().message}</p>
          </div>
        </div>
        <div>
          <p className={'text-white opacity-80'}>Buono sconto</p>
          <p
            className={'text-white text-[16px] font-medium text-balance leading-[1.3]'}>{props.description ||
            <span className={'text-white italic opacity-60'}>Descrizione</span>}
          </p>
        </div>
      </div>
    </div>
  );
};
export default OneTimePromoWidget;
