import { Button, Form, Input, Layout, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { blue, green } from '@ant-design/colors';
import { createFileRoute, Link, useRouteContext, useRouter } from '@tanstack/react-router';
import { supabase } from '../../../utils/supabase';
import {
  trackEvent
} from '../../../utils/Matomo/tracking_functions';
import * as Sentry from "@sentry/react";
import { usePostHog } from 'posthog-js/react';

export const Route = createFileRoute('/_public/login')({
  component: RouteComponent
});

function RouteComponent() {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [form] = Form.useForm();
  const posthog = usePostHog()

  async function resetPassword() {
    const email = form.getFieldValue('email');
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://app.nextisfree.io/update-password/'
    });
    if (error) {
      Sentry.captureException(error);
      message.error(error.message);
      return;
    }
    setResetPasswordSent(true);
    trackEvent('Password Recovery', 'Reset email sent', email);
  }

  async function onFinish(values: any) {
    posthog.capture('login-form-submitted', { email: values.email });
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: values.email,
      password: values.password
    });
    if (error) {
      messageApi.error('Email o password non corretti');
      posthog.captureException(error, {...error, message: error.message});
      setLoading(false);
      return;
    }
    if (data) {
      if (data.user.user_metadata.shop !== true) {
        await supabase.auth.updateUser({ data: { shop: true } });
      }
      posthog.capture('login-successful', { email: values.email });
      window.location.reload();
    }
  }

  return (
    <div data-matomo-form={true} data-matomo-name="login">
      <h1 className={'font-bold text-2xl'} style={{ marginBottom: 30 }}>Accedi</h1>
      <Form
        name={'LoginForm'}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        {contextHolder}
        <Form.Item
          required={false}
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email non valida'
            }
          ]}
        >
          <Input placeholder={'Email'} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          required={false}
          rules={[
            {
              required: true,
              message: 'Password non valida'
            }
          ]}
        >
          <Input.Password placeholder={'Password'} />
        </Form.Item>

        <div style={{ textAlign: 'center' }}>
          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              style={{ width: '100%', marginTop: 10 }}
              type="primary"
              htmlType="submit"
              size="large"
            >
              Accedi
            </Button>
          </Form.Item>
          <AnimatePresence>
            {resetPasswordSent ? (
              <motion.div
                style={{ lineHeight: 1.2, color: blue[4] }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                onClick={resetPassword}
              >
                Se l'email inserita appartiene ad un account, riceverai un'email
                per il recupero della password
              </motion.div>
            ) : (
              <motion.a className={'text-blue-600'} onClick={resetPassword}>
                Ho dimenticato la password
              </motion.a>
            )}
          </AnimatePresence>
        </div>
      </Form>
      <p
        style={{
          fontSize: 14,
          textAlign: 'center',
          width: '100%',
          margin: '10px 0',
          marginTop: 40
        }}
      >
        Non hai un account?
      </p>
      <Link to={'/sign-up'}>
        <Button
          size={'large'}
          style={{ width: '100%' }}
        >
          Registrati
        </Button>
      </Link>
    </div>
  );
}
