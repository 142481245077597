import i18next from 'i18next';
import { errorsTranslations } from './typesafe-resources/uiErrors';
import { uiMessagesTranslations } from './typesafe-resources/uiMessages';
export * from './typesafe-resources/uiErrors';
export * from './typesafe-resources/uiMessages';

i18next.init({
  compatibilityJSON: 'v3',
  resources: {
    en: { errors:  errorsTranslations.en, uiMessages: uiMessagesTranslations.en},
    it: { errors:  errorsTranslations.it, uiMessages: uiMessagesTranslations.it},
  },
  lng: 'en', // default language
  fallbackLng: 'en',
});

export default i18next;
