/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PublicImport } from './routes/_public'
import { Route as OnBoardingImport } from './routes/_on-boarding'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as PublicSignUpImport } from './routes/_public/sign-up'
import { Route as PublicLoginImport } from './routes/_public/login'
import { Route as PublicConfirmEmailImport } from './routes/_public/confirm-email'
import { Route as OnBoardingUpdatePasswordImport } from './routes/_on-boarding/update-password'
import { Route as OnBoardingOnBoardingImport } from './routes/_on-boarding/on-boarding'
import { Route as AuthenticatedResourcesImport } from './routes/_authenticated/resources'
import { Route as AuthenticatedCustomizeImport } from './routes/_authenticated/customize'
import { Route as AuthenticatedBillingsImport } from './routes/_authenticated/billings'
import { Route as AuthenticatedAnalyticsImport } from './routes/_authenticated/analytics'
import { Route as AuthenticatedSettingsRouteImport } from './routes/_authenticated/settings/route'
import { Route as AuthenticatedTransactionsIndexImport } from './routes/_authenticated/transactions/index'
import { Route as AuthenticatedSettingsIndexImport } from './routes/_authenticated/settings/index'
import { Route as AuthenticatedNotificationsIndexImport } from './routes/_authenticated/notifications/index'
import { Route as AuthenticatedTransactionsCaptureImport } from './routes/_authenticated/transactions/capture'
import { Route as AuthenticatedSettingsStaffImport } from './routes/_authenticated/settings/staff'
import { Route as AuthenticatedNotificationsNewImport } from './routes/_authenticated/notifications/new'

// Create/Update Routes

const PublicRoute = PublicImport.update({
  id: '/_public',
  getParentRoute: () => rootRoute,
} as any)

const OnBoardingRoute = OnBoardingImport.update({
  id: '/_on-boarding',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const PublicSignUpRoute = PublicSignUpImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => PublicRoute,
} as any)

const PublicLoginRoute = PublicLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => PublicRoute,
} as any)

const PublicConfirmEmailRoute = PublicConfirmEmailImport.update({
  id: '/confirm-email',
  path: '/confirm-email',
  getParentRoute: () => PublicRoute,
} as any)

const OnBoardingUpdatePasswordRoute = OnBoardingUpdatePasswordImport.update({
  id: '/update-password',
  path: '/update-password',
  getParentRoute: () => OnBoardingRoute,
} as any)

const OnBoardingOnBoardingRoute = OnBoardingOnBoardingImport.update({
  id: '/on-boarding',
  path: '/on-boarding',
  getParentRoute: () => OnBoardingRoute,
} as any)

const AuthenticatedResourcesRoute = AuthenticatedResourcesImport.update({
  id: '/resources',
  path: '/resources',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedCustomizeRoute = AuthenticatedCustomizeImport.update({
  id: '/customize',
  path: '/customize',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedBillingsRoute = AuthenticatedBillingsImport.update({
  id: '/billings',
  path: '/billings',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedAnalyticsRoute = AuthenticatedAnalyticsImport.update({
  id: '/analytics',
  path: '/analytics',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedSettingsRouteRoute = AuthenticatedSettingsRouteImport.update(
  {
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedTransactionsIndexRoute =
  AuthenticatedTransactionsIndexImport.update({
    id: '/transactions/',
    path: '/transactions/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedSettingsIndexRoute = AuthenticatedSettingsIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedSettingsRouteRoute,
  } as any,
)

const AuthenticatedNotificationsIndexRoute =
  AuthenticatedNotificationsIndexImport.update({
    id: '/notifications/',
    path: '/notifications/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedTransactionsCaptureRoute =
  AuthenticatedTransactionsCaptureImport.update({
    id: '/transactions/capture',
    path: '/transactions/capture',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedSettingsStaffRoute = AuthenticatedSettingsStaffImport.update(
  {
    id: '/staff',
    path: '/staff',
    getParentRoute: () => AuthenticatedSettingsRouteRoute,
  } as any,
)

const AuthenticatedNotificationsNewRoute =
  AuthenticatedNotificationsNewImport.update({
    id: '/notifications/new',
    path: '/notifications/new',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_on-boarding': {
      id: '/_on-boarding'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof OnBoardingImport
      parentRoute: typeof rootRoute
    }
    '/_public': {
      id: '/_public'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PublicImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/settings': {
      id: '/_authenticated/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthenticatedSettingsRouteImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/analytics': {
      id: '/_authenticated/analytics'
      path: '/analytics'
      fullPath: '/analytics'
      preLoaderRoute: typeof AuthenticatedAnalyticsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/billings': {
      id: '/_authenticated/billings'
      path: '/billings'
      fullPath: '/billings'
      preLoaderRoute: typeof AuthenticatedBillingsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/customize': {
      id: '/_authenticated/customize'
      path: '/customize'
      fullPath: '/customize'
      preLoaderRoute: typeof AuthenticatedCustomizeImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/resources': {
      id: '/_authenticated/resources'
      path: '/resources'
      fullPath: '/resources'
      preLoaderRoute: typeof AuthenticatedResourcesImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_on-boarding/on-boarding': {
      id: '/_on-boarding/on-boarding'
      path: '/on-boarding'
      fullPath: '/on-boarding'
      preLoaderRoute: typeof OnBoardingOnBoardingImport
      parentRoute: typeof OnBoardingImport
    }
    '/_on-boarding/update-password': {
      id: '/_on-boarding/update-password'
      path: '/update-password'
      fullPath: '/update-password'
      preLoaderRoute: typeof OnBoardingUpdatePasswordImport
      parentRoute: typeof OnBoardingImport
    }
    '/_public/confirm-email': {
      id: '/_public/confirm-email'
      path: '/confirm-email'
      fullPath: '/confirm-email'
      preLoaderRoute: typeof PublicConfirmEmailImport
      parentRoute: typeof PublicImport
    }
    '/_public/login': {
      id: '/_public/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof PublicLoginImport
      parentRoute: typeof PublicImport
    }
    '/_public/sign-up': {
      id: '/_public/sign-up'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof PublicSignUpImport
      parentRoute: typeof PublicImport
    }
    '/_authenticated/notifications/new': {
      id: '/_authenticated/notifications/new'
      path: '/notifications/new'
      fullPath: '/notifications/new'
      preLoaderRoute: typeof AuthenticatedNotificationsNewImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/settings/staff': {
      id: '/_authenticated/settings/staff'
      path: '/staff'
      fullPath: '/settings/staff'
      preLoaderRoute: typeof AuthenticatedSettingsStaffImport
      parentRoute: typeof AuthenticatedSettingsRouteImport
    }
    '/_authenticated/transactions/capture': {
      id: '/_authenticated/transactions/capture'
      path: '/transactions/capture'
      fullPath: '/transactions/capture'
      preLoaderRoute: typeof AuthenticatedTransactionsCaptureImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/notifications/': {
      id: '/_authenticated/notifications/'
      path: '/notifications'
      fullPath: '/notifications'
      preLoaderRoute: typeof AuthenticatedNotificationsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/settings/': {
      id: '/_authenticated/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof AuthenticatedSettingsIndexImport
      parentRoute: typeof AuthenticatedSettingsRouteImport
    }
    '/_authenticated/transactions/': {
      id: '/_authenticated/transactions/'
      path: '/transactions'
      fullPath: '/transactions'
      preLoaderRoute: typeof AuthenticatedTransactionsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedSettingsRouteRouteChildren {
  AuthenticatedSettingsStaffRoute: typeof AuthenticatedSettingsStaffRoute
  AuthenticatedSettingsIndexRoute: typeof AuthenticatedSettingsIndexRoute
}

const AuthenticatedSettingsRouteRouteChildren: AuthenticatedSettingsRouteRouteChildren =
  {
    AuthenticatedSettingsStaffRoute: AuthenticatedSettingsStaffRoute,
    AuthenticatedSettingsIndexRoute: AuthenticatedSettingsIndexRoute,
  }

const AuthenticatedSettingsRouteRouteWithChildren =
  AuthenticatedSettingsRouteRoute._addFileChildren(
    AuthenticatedSettingsRouteRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedSettingsRouteRoute: typeof AuthenticatedSettingsRouteRouteWithChildren
  AuthenticatedAnalyticsRoute: typeof AuthenticatedAnalyticsRoute
  AuthenticatedBillingsRoute: typeof AuthenticatedBillingsRoute
  AuthenticatedCustomizeRoute: typeof AuthenticatedCustomizeRoute
  AuthenticatedResourcesRoute: typeof AuthenticatedResourcesRoute
  AuthenticatedNotificationsNewRoute: typeof AuthenticatedNotificationsNewRoute
  AuthenticatedTransactionsCaptureRoute: typeof AuthenticatedTransactionsCaptureRoute
  AuthenticatedNotificationsIndexRoute: typeof AuthenticatedNotificationsIndexRoute
  AuthenticatedTransactionsIndexRoute: typeof AuthenticatedTransactionsIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedSettingsRouteRoute: AuthenticatedSettingsRouteRouteWithChildren,
  AuthenticatedAnalyticsRoute: AuthenticatedAnalyticsRoute,
  AuthenticatedBillingsRoute: AuthenticatedBillingsRoute,
  AuthenticatedCustomizeRoute: AuthenticatedCustomizeRoute,
  AuthenticatedResourcesRoute: AuthenticatedResourcesRoute,
  AuthenticatedNotificationsNewRoute: AuthenticatedNotificationsNewRoute,
  AuthenticatedTransactionsCaptureRoute: AuthenticatedTransactionsCaptureRoute,
  AuthenticatedNotificationsIndexRoute: AuthenticatedNotificationsIndexRoute,
  AuthenticatedTransactionsIndexRoute: AuthenticatedTransactionsIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface OnBoardingRouteChildren {
  OnBoardingOnBoardingRoute: typeof OnBoardingOnBoardingRoute
  OnBoardingUpdatePasswordRoute: typeof OnBoardingUpdatePasswordRoute
}

const OnBoardingRouteChildren: OnBoardingRouteChildren = {
  OnBoardingOnBoardingRoute: OnBoardingOnBoardingRoute,
  OnBoardingUpdatePasswordRoute: OnBoardingUpdatePasswordRoute,
}

const OnBoardingRouteWithChildren = OnBoardingRoute._addFileChildren(
  OnBoardingRouteChildren,
)

interface PublicRouteChildren {
  PublicConfirmEmailRoute: typeof PublicConfirmEmailRoute
  PublicLoginRoute: typeof PublicLoginRoute
  PublicSignUpRoute: typeof PublicSignUpRoute
}

const PublicRouteChildren: PublicRouteChildren = {
  PublicConfirmEmailRoute: PublicConfirmEmailRoute,
  PublicLoginRoute: PublicLoginRoute,
  PublicSignUpRoute: PublicSignUpRoute,
}

const PublicRouteWithChildren =
  PublicRoute._addFileChildren(PublicRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof PublicRouteWithChildren
  '/settings': typeof AuthenticatedSettingsRouteRouteWithChildren
  '/analytics': typeof AuthenticatedAnalyticsRoute
  '/billings': typeof AuthenticatedBillingsRoute
  '/customize': typeof AuthenticatedCustomizeRoute
  '/resources': typeof AuthenticatedResourcesRoute
  '/on-boarding': typeof OnBoardingOnBoardingRoute
  '/update-password': typeof OnBoardingUpdatePasswordRoute
  '/confirm-email': typeof PublicConfirmEmailRoute
  '/login': typeof PublicLoginRoute
  '/sign-up': typeof PublicSignUpRoute
  '/notifications/new': typeof AuthenticatedNotificationsNewRoute
  '/settings/staff': typeof AuthenticatedSettingsStaffRoute
  '/transactions/capture': typeof AuthenticatedTransactionsCaptureRoute
  '/notifications': typeof AuthenticatedNotificationsIndexRoute
  '/settings/': typeof AuthenticatedSettingsIndexRoute
  '/transactions': typeof AuthenticatedTransactionsIndexRoute
}

export interface FileRoutesByTo {
  '': typeof PublicRouteWithChildren
  '/analytics': typeof AuthenticatedAnalyticsRoute
  '/billings': typeof AuthenticatedBillingsRoute
  '/customize': typeof AuthenticatedCustomizeRoute
  '/resources': typeof AuthenticatedResourcesRoute
  '/on-boarding': typeof OnBoardingOnBoardingRoute
  '/update-password': typeof OnBoardingUpdatePasswordRoute
  '/confirm-email': typeof PublicConfirmEmailRoute
  '/login': typeof PublicLoginRoute
  '/sign-up': typeof PublicSignUpRoute
  '/notifications/new': typeof AuthenticatedNotificationsNewRoute
  '/settings/staff': typeof AuthenticatedSettingsStaffRoute
  '/transactions/capture': typeof AuthenticatedTransactionsCaptureRoute
  '/notifications': typeof AuthenticatedNotificationsIndexRoute
  '/settings': typeof AuthenticatedSettingsIndexRoute
  '/transactions': typeof AuthenticatedTransactionsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_on-boarding': typeof OnBoardingRouteWithChildren
  '/_public': typeof PublicRouteWithChildren
  '/_authenticated/settings': typeof AuthenticatedSettingsRouteRouteWithChildren
  '/_authenticated/analytics': typeof AuthenticatedAnalyticsRoute
  '/_authenticated/billings': typeof AuthenticatedBillingsRoute
  '/_authenticated/customize': typeof AuthenticatedCustomizeRoute
  '/_authenticated/resources': typeof AuthenticatedResourcesRoute
  '/_on-boarding/on-boarding': typeof OnBoardingOnBoardingRoute
  '/_on-boarding/update-password': typeof OnBoardingUpdatePasswordRoute
  '/_public/confirm-email': typeof PublicConfirmEmailRoute
  '/_public/login': typeof PublicLoginRoute
  '/_public/sign-up': typeof PublicSignUpRoute
  '/_authenticated/notifications/new': typeof AuthenticatedNotificationsNewRoute
  '/_authenticated/settings/staff': typeof AuthenticatedSettingsStaffRoute
  '/_authenticated/transactions/capture': typeof AuthenticatedTransactionsCaptureRoute
  '/_authenticated/notifications/': typeof AuthenticatedNotificationsIndexRoute
  '/_authenticated/settings/': typeof AuthenticatedSettingsIndexRoute
  '/_authenticated/transactions/': typeof AuthenticatedTransactionsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/settings'
    | '/analytics'
    | '/billings'
    | '/customize'
    | '/resources'
    | '/on-boarding'
    | '/update-password'
    | '/confirm-email'
    | '/login'
    | '/sign-up'
    | '/notifications/new'
    | '/settings/staff'
    | '/transactions/capture'
    | '/notifications'
    | '/settings/'
    | '/transactions'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/analytics'
    | '/billings'
    | '/customize'
    | '/resources'
    | '/on-boarding'
    | '/update-password'
    | '/confirm-email'
    | '/login'
    | '/sign-up'
    | '/notifications/new'
    | '/settings/staff'
    | '/transactions/capture'
    | '/notifications'
    | '/settings'
    | '/transactions'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_on-boarding'
    | '/_public'
    | '/_authenticated/settings'
    | '/_authenticated/analytics'
    | '/_authenticated/billings'
    | '/_authenticated/customize'
    | '/_authenticated/resources'
    | '/_on-boarding/on-boarding'
    | '/_on-boarding/update-password'
    | '/_public/confirm-email'
    | '/_public/login'
    | '/_public/sign-up'
    | '/_authenticated/notifications/new'
    | '/_authenticated/settings/staff'
    | '/_authenticated/transactions/capture'
    | '/_authenticated/notifications/'
    | '/_authenticated/settings/'
    | '/_authenticated/transactions/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  OnBoardingRoute: typeof OnBoardingRouteWithChildren
  PublicRoute: typeof PublicRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  OnBoardingRoute: OnBoardingRouteWithChildren,
  PublicRoute: PublicRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/_on-boarding",
        "/_public"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/settings",
        "/_authenticated/analytics",
        "/_authenticated/billings",
        "/_authenticated/customize",
        "/_authenticated/resources",
        "/_authenticated/notifications/new",
        "/_authenticated/transactions/capture",
        "/_authenticated/notifications/",
        "/_authenticated/transactions/"
      ]
    },
    "/_on-boarding": {
      "filePath": "_on-boarding.tsx",
      "children": [
        "/_on-boarding/on-boarding",
        "/_on-boarding/update-password"
      ]
    },
    "/_public": {
      "filePath": "_public.tsx",
      "children": [
        "/_public/confirm-email",
        "/_public/login",
        "/_public/sign-up"
      ]
    },
    "/_authenticated/settings": {
      "filePath": "_authenticated/settings/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/settings/staff",
        "/_authenticated/settings/"
      ]
    },
    "/_authenticated/analytics": {
      "filePath": "_authenticated/analytics.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/billings": {
      "filePath": "_authenticated/billings.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/customize": {
      "filePath": "_authenticated/customize.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/resources": {
      "filePath": "_authenticated/resources.tsx",
      "parent": "/_authenticated"
    },
    "/_on-boarding/on-boarding": {
      "filePath": "_on-boarding/on-boarding.tsx",
      "parent": "/_on-boarding"
    },
    "/_on-boarding/update-password": {
      "filePath": "_on-boarding/update-password.tsx",
      "parent": "/_on-boarding"
    },
    "/_public/confirm-email": {
      "filePath": "_public/confirm-email.tsx",
      "parent": "/_public"
    },
    "/_public/login": {
      "filePath": "_public/login.tsx",
      "parent": "/_public"
    },
    "/_public/sign-up": {
      "filePath": "_public/sign-up.tsx",
      "parent": "/_public"
    },
    "/_authenticated/notifications/new": {
      "filePath": "_authenticated/notifications/new.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/settings/staff": {
      "filePath": "_authenticated/settings/staff.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/transactions/capture": {
      "filePath": "_authenticated/transactions/capture.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/notifications/": {
      "filePath": "_authenticated/notifications/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/settings/": {
      "filePath": "_authenticated/settings/index.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/transactions/": {
      "filePath": "_authenticated/transactions/index.tsx",
      "parent": "/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
