import { z } from 'zod';

export const shopStaffCreateResponseSchema = z.object({
  display_name: z.string().min(3),
  username: z.string().min(3),
  password: z.string().min(8)
});

export type ShopStaffCreateResponse = z.infer<typeof shopStaffCreateResponseSchema>

export const shopStaffSchema = z.object({id: z.string().uuid(), username: z.string(), display_name: z.string(), archived: z.boolean() });

export type ShopStaff = z.infer<typeof shopStaffSchema>

export const shopStaffLoginResponseSchema = z.object({ member_id: z.string().uuid(), access_token: z.string(), refresh_token: z.string() });

export type ShopStaffLoginResponse = z.infer<typeof shopStaffLoginResponseSchema>

export type ShopStaffRoles = 'admin' | 'cashier' | 'editor'
