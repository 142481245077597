import { Modal } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { apiV1 } from '../../utils/apiClient';
import { Notification, NotificationWidget } from '@next-is-free-monorepo/next-is-free-types';
import NotificationPreview from '../NotificationPreview';
import Loader from '../Loader';

const NotificationPreviewModal = ({ id, onCancel }: { id: string, onCancel: () => void }) => {

  const notification = useQuery({
    queryKey: ['notification', id],
    queryFn: async () => await apiV1.get<Notification>(`/shop/notifications/${id}`)
  });

  const notificationWidgets = useQuery({
    queryKey: ['notificationWidgets', id],
    queryFn: async () => apiV1.get<NotificationWidget[]>(`/shop/notifications/${id}/widgets?widget_types=${notification.data?.widgets?.join('&widget_types=') || '[]'}`),
    enabled: !!notification.data?.widgets && notification.data.widgets?.length > 0
  });

  return (
    <Modal open footer={null} onCancel={onCancel}>
      {notification.isLoading || !notification.data || notificationWidgets.isLoading
        ? <Loader/> :
        <div className={'mt-5'}>
          <NotificationPreview text={notification.data.text} title={notification.data.title} imageUrl={''}
                               ctaLabel={notification.data?.call_to_actions?.[0].label} ctaType={notification.data?.call_to_actions?.[0].type || 'no-cta'}
                               ctaValue={notification.data?.call_to_actions?.[0].value || ''} widgets={notificationWidgets.data}
                               notificationStatus={notification.data.status} />
        </div>
      }

    </Modal>
  );
};
export default NotificationPreviewModal;
