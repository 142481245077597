import { createFileRoute } from '@tanstack/react-router';
import BgIcon from '../../../../microcomponents/BgIcon';
import { HugeiconsIcon } from '@hugeicons/react';
import {
  Archive01FreeIcons,
  Archive02FreeIcons,
  KeyIcon,
  LockKeyIcon,
  User02FreeIcons
} from '@hugeicons/core-free-icons';
import { Avatar, Button, Collapse, Dropdown, Input, MenuProps } from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import RoundMoreButton from '../../../../microcomponents/RoundMoreButton';
import { IconArchive, IconDots, IconRefresh } from '@tabler/icons-react';
import * as React from 'react';
import { COLORS } from '@next-is-free-monorepo/globals';
import CModal from '../../../../components/CModal';
import NewStaffMemberModal from '../../../../components/settings/NewStaffMemberModal';
import { useState } from 'react';
import { useMutation, UseMutationOptions, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { staffQueryOptions } from '../../../../utils/queryOptions';
import Loader from '../../../../components/Loader';
import { apiV1 } from '../../../../utils/apiClient';
import SingleCollapse from '../../../../components/SingleCollapse';
import CopyText from '../../../../microcomponents/CopyText';

export const Route = createFileRoute('/_authenticated/settings/staff')({
  component: RouteComponent,
  pendingComponent: props => <Loader />
});

function RouteComponent() {

  const [newMemberModalOpen, setNewMemberModalOpen] = useState(false);

  const staff = useSuspenseQuery(staffQueryOptions({ withArchived: true }));

  return (
    <div>
      <div>
        <p className={'text-slate-500 mb-2'}>
          Qui puoi aggiungere i membri del tuo staff e generare credenziali che danno accesso esclusivamente all'app
          business. In questo modo puoi tenere al sicuro le credenziali del tuo account principale.
        </p>
        <div className={'flex items-center gap-2 mt-5 mb-3 justify-between'}>
          <div className={'flex items-center gap-2'}>
            <BgIcon icon={<HugeiconsIcon icon={LockKeyIcon} strokeWidth={1} />} color={'blue'} />
            <p className={'font-medium'}>Membri attivi</p>
          </div>
          <Button type={'primary'} icon={<PlusOutlined />} size={'small'}
                  onClick={() => setNewMemberModalOpen(true)}>Crea</Button>
        </div>
        {staff.data?.map((member) => (
          !member.archived &&
          <Row {...member} displayName={member.display_name} key={member.username} />
        ))}
        {!staff.data?.some((item) => !item.archived) &&
          <div className={'flex items-center justify-center mt-5 bg-slate-50 p-2 rounded-md'}>
            <p className={'text-slate-500'}>Nessun membro attivo</p>
          </div>
        }
        <SingleCollapse header={<div className={'flex items-center gap-2'}>
          <BgIcon icon={<HugeiconsIcon icon={Archive02FreeIcons} strokeWidth={1} />} color={'black'} />
          <p className={'font-medium'}>Membri archiviati</p>
        </div>}>
          <div>
            {staff.data?.map((member) => (
              member.archived &&
              <Row {...member} displayName={member.display_name} key={member.username} />
            ))}
          </div>
        </SingleCollapse>
      </div>
      <NewStaffMemberModal open={newMemberModalOpen} setOpen={setNewMemberModalOpen} />
    </div>
  );
}

function Row({ id, displayName, username, archived }: {
  id: string,
  displayName: string,
  username: string,
  archived: boolean
}) {

  const [confirmArchiveOpen, setConfirmArchiveOpen] = useState(false);
  const [newPassword, setNewPassword] = useState<undefined | string>(undefined);
  const queryClient = useQueryClient();

  const refreshPassword = useMutation({
    mutationFn: async ()=> await apiV1.get<string>(`/shop/staff/${id}/refresh-password`),
    onSuccess(data) {
      setNewPassword(data);
    }
  })

  const archive: UseMutationOptions = {
    mutationFn: async () => await apiV1.put(`/shop/staff/${id}/archive`, {}),
    onSuccess() {
      setConfirmArchiveOpen(false);
      queryClient.invalidateQueries({ queryKey: ['staff'] });
    }
  };

  const sentOptions: MenuProps['items'] = [
    {
      key: '1',
      label: 'Rigenera password',
      icon: <IconRefresh size={16} />,
      onClick: () => {
        refreshPassword.mutate();
      }
    },
    {
      key: '2',
      label: 'Archivia',
      icon: <IconArchive size={16} />,
      onClick: () => {
        setConfirmArchiveOpen(true);
      }
    }
  ];


  return (
    <div className={`border p-3 rounded-lg mt-2 flex items-center justify-between ${archived ? 'bg-slate-50' : ''}`}>
      <div className={'items-center flex gap-2'}>
        <Avatar className={'bg-slate-200 text-slate-500'}><HugeiconsIcon icon={User02FreeIcons} size={19} /></Avatar>
        <div>
          <p className={'font-medium leading-none text-sm'}>{displayName}</p>
          <p className={'text-slate-500 leading-none text-xs'}>{username}</p>
        </div>
      </div>
      {!archived &&
        <Dropdown trigger={['click']} menu={{ items: sentOptions }}>
          <button className={'hover:bg-slate-50 cursor-pointer p-2 rounded-full'}>
            <IconDots color={COLORS.slate['500']} size={18} />
          </button>
        </Dropdown>
      }
      <CModal open={confirmArchiveOpen} color={'red'} title={'Archivia membro'}
              text={'Archiviando questo membro non avrà più accesso all\'App Business'} confirmButtonText={'Archivia'}
              onClose={() => setConfirmArchiveOpen(false)} icon={<HugeiconsIcon icon={Archive02FreeIcons} />}
              mutationOptions={archive} />
      <CModal open={!!newPassword} onClose={() => setNewPassword(undefined)} icon={<HugeiconsIcon icon={KeyIcon} />}
              mutationOptions={{}} hideConfirmButton cancelButtonText={'Chiudi'}>
        <CopyText label={'Nuova Password'} text={newPassword || ''} />
      </CModal>
    </div>
  );
}
