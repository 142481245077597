import OneTimePromoWidget from './OneTimePromoWidgets';
import { NotificationStatus, NotificationWidget } from '@next-is-free-monorepo/next-is-free-types';

interface NotificationPreviewWidgetsSwitchProps {}

const NotificationPreviewWidgetsSwitch = (props: NotificationWidget & {scale: number, notificationStatus: NotificationStatus})=>{
  if(props.type === 'one_time_promo'){
    return <OneTimePromoWidget {...props.options} scale={props.scale} notificationStatus={props.notificationStatus} />
  }else{
    //leave this to allow for future widget types
    return <div/>
  }
}
export default NotificationPreviewWidgetsSwitch;
