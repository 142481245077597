import { HugeiconsIcon } from '@hugeicons/react';
import { Copy01FreeIcons } from '@hugeicons/core-free-icons';
import {Tooltip } from 'antd';
import { useState } from 'react';

interface CopyTextProps {}

const CopyText = ({label, text}: {label: string, text: string})=>{

  const [tooltipOpen, setTooltipOpen] = useState(false);

  //function to copy text to clipboard
  const copyToClipboard = (text: string) => {
    setTooltipOpen(true)
    setTimeout(() => {
      setTooltipOpen(false)
    }, 1000);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setTooltipOpen(false)
      alert('Copiato')
    }, 200);
  }

return(
  <Tooltip title={'Copiato'} placement={'topRight'} open={tooltipOpen}>
    <p className={'font-medium text-sm'}>{label}</p>
    <div onClick={()=>copyToClipboard(text)} className="flex items-center p-2 border rounded-md w-full cursor-pointer hover:bg-slate-50 justify-between">
      <p className={'overflow-hidden line-clamp-1 text-slate-600'}>
        {text}
      </p>
      <HugeiconsIcon icon={Copy01FreeIcons} size={16}/>
    </div>
  </Tooltip>

);
}
export default CopyText;
