import { z } from 'zod';

export const UserOneTimePromo = z.object({
  id: z.string(),
  description: z.string(),
  shop_id: z.string(),
  business_name: z.string(),
  image: z.boolean(),
  brand_color: z.string(),
  notification_id: z.string(),
  valid_from: z.string().optional(),
  valid_to: z.string().optional(),
  shop_latest_update_at: z.string(),
})

export type UserOneTimePromo = z.infer<typeof UserOneTimePromo>
