import { Select } from 'antd';
import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiV1 } from '../utils/apiClient';
import { SalesPointPreview } from '@next-is-free-monorepo/next-is-free-types';
import { salesPointsQueryOptions } from '../utils/queryOptions';

interface SalesPointsSelectProps {
  setSelectedSalesPointId: (value: string) => void;
  selectedSalesPointId: string;
  size? : 'small' | 'middle' | 'large';
}

const SalesPointsSelect = ({ setSelectedSalesPointId, selectedSalesPointId, size = 'middle' }: SalesPointsSelectProps) => {

  const salesPoints = useSuspenseQuery(salesPointsQueryOptions());

  let salesPointsOptions = salesPoints.data.map((point) => ({
    value: point.id,
    label: point.name
  }));

  salesPointsOptions = [...salesPointsOptions, { value: '', label: 'Tutti i punti vendita' }];

  if(salesPointsOptions.length === 2){
    return <></>
  }

  return (<Select size={size} style={{ width: 200, flex: 1 }}
                  placeholder={'Punto vendita'}
                  options={salesPointsOptions}
                  onSelect={setSelectedSalesPointId}
                  value={selectedSalesPointId} />);
};
export default SalesPointsSelect;
