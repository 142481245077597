import SimpleStatistic from "./charts/SimpleStatistic";
import { green, orange, red } from "@ant-design/colors";
import { useSuspenseQuery } from '@tanstack/react-query';
import { endDate, startingDate } from '../../src/routes/_authenticated/analytics';
import { customersQueryOptions } from '../../utils/queryOptions';
import dayjs from 'dayjs';
import { StepsFeedbackBadgeProps } from '../../microcomponents/StepsBadge';


const CustomersActiveNotifications = () => {

  const customers = useSuspenseQuery(customersQueryOptions(startingDate.value, endDate.value));

  function calculateNotificationPercentage(usersArray: any) {
    // Filter the array to include only users with notifications_allowed set to true
    const usersWithNotifications = usersArray.filter((user: any) => user.notifications_allowed);

    // Calculate the percentage of users with active notifications
    return (usersWithNotifications.length / usersArray.length) * 100;
  }

  let number = 0;
  let noData = false;
  const customersCount = customers?.data?.length || 0

 if(customersCount < 1){
    noData = true
  }else {
    number = calculateNotificationPercentage(customers.data);
  }

  function giveFeedback(){
    if(noData){
      return {tooltip: 'Non ci sono dati sufficenti'}
    }
    if(number > 79)
      return {tooltip: 'Ottimo! la percentuale è sopra la media'}
    if(number > 35)
      return {tooltip: 'La percentuale è nella media'}
    else
      return {tooltip: 'Da migliorare, la percentuale è sotto la media'}
  }

  const stepsFeedbackBadge : StepsFeedbackBadgeProps = {
    selectedStep: number > 79 ? 3 : number > 35 ? 2 : 1,
    mode: 'progress',
    steps: [
      {color: 'red'},
      {color: 'yellow'},
      {color: 'green'}
    ]
  }

  return <SimpleStatistic tooltipText={giveFeedback().tooltip} stepsFeedbackBadge={stepsFeedbackBadge} noData={noData} title={'Clienti con notifiche attive'} suffix={'%'} value={number}/>;
};
export default CustomersActiveNotifications;
