import * as React from 'react';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { IconArchive, IconClock, IconDots, IconEdit, IconSend, IconTrack, IconTrash } from '@tabler/icons-react';
import { NotificationStatus } from '@next-is-free-monorepo/next-is-free-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiV1 } from '../../utils/apiClient';
import NotificationScheduleModal from './NotificationScheduleModal';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import ConfirmModal from '../ConfirmModal';
import CModal from '../CModal';

interface NotificationOptionBtnProps {
}


const NotificationOptionBtn = ({ status, notificationId, setSelectedNotificationId }: {
  status: NotificationStatus,
  notificationId: string,
  setSelectedNotificationId: (id: undefined) => void
}) => {

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);

  const queryClient = useQueryClient();

  const archive = {
    mutationKey: ['notification', 'archive'],
    mutationFn: async () => await apiV1.put(`/shop/notifications/${notificationId}/archive`, {}),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notifications'] });
      setConfirmArchive(false);
      message.success('Notifica archiviata');
    }
  };

  const deleteNotification = {
    mutationKey: ['notification', 'delete'],
    mutationFn: async () => await apiV1.delete(`/shop/notifications/${notificationId}`, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      setConfirmDelete(false);
      setSelectedNotificationId(undefined);
      message.success('Notifica eliminata');
    }
  };

  const rescheduleNotification = useMutation({
    mutationKey: ['notification', 'reschedule'],
    mutationFn: async (schedule_time: Dayjs) => await apiV1.put(`/shop/notifications/${notificationId}`, { schedule_time: schedule_time.toISOString() }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      setScheduleModalOpen(false);
    }
  });

  const pendingOptions: MenuProps['items'] = [
    {
      key: '1',
      label: 'Riprogramma',
      icon: <IconClock size={16} />,
      onClick: () => setScheduleModalOpen(true)
    },
    /*    {
          key: '2',
          label: 'Invia Subito',
          icon: <IconSend size={16} />
        },*/
    {
      key: '3',
      danger: true,
      label: 'Elimina',
      icon: <IconTrash size={16} />,
      onClick: () => setConfirmDelete(true)
    }
  ];

  const sentOptions: MenuProps['items'] = [
    {
      key: '1',
      label: 'Archivia',
      icon: <IconArchive size={16} />,
      onClick: () => setConfirmArchive(true)
    }
  ];

  const options = status === 'pending' ? pendingOptions : sentOptions;

  return (
    <>
      <NotificationScheduleModal open={scheduleModalOpen} onSubmit={rescheduleNotification.mutate}
                                 loading={rescheduleNotification.isPending} onCancel={() => setScheduleModalOpen(false)}
                                 defaultValue={dayjs()} />
      <Dropdown disabled={status === 'archived'} trigger={['click']} menu={{ items: options }}>
        <Button className={'p-0 w-[32px] h-[32px] rounded-3xl flex items-center justify-center'}>
          <IconDots size={16} />
        </Button>
      </Dropdown>
      <CModal color={'red'} mutationOptions={deleteNotification} title={'Vuoi davvero eliminare questa notifica?'}
              text={'questa azione è irreversibile'} open={confirmDelete} onClose={() => setConfirmDelete(false)}
              icon={<IconTrash />} />
      <CModal color={'black'} mutationOptions={archive} title={'Vuoi davvero archiviare questa notifica?'}
              text={'La notifica non sarà più visibile. Questa azione è irreversibile'} open={confirmArchive}
              onClose={() => setConfirmArchive(false)}
              icon={<IconArchive />} />
    </>

  );
};
export default NotificationOptionBtn;
