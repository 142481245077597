import * as React from 'react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button, DatePicker, Flex, Skeleton, Table } from 'antd';
import { Suspense, useState } from 'react';
import dayjs from 'dayjs';
import { blue, green, purple, red } from '@ant-design/colors';
import { useInfiniteQuery, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import PageLoader from '../../../../microcomponents/PageLoader';
import {
  paginatedTransactionsQueryOptions, salesPointsQueryOptions,
  staffQueryOptions,
  transactionsQueryOptions
} from '../../../../utils/queryOptions';
import SalesPointsSelect from '../../../../components/SalesPointsSelect';
import { IconArrowDown, IconArrowDownCircle, IconRotate2, IconScan } from '@tabler/icons-react';
import useShopAttrs from '../../../../utils/hooks/useShopAttrs';
import { HugeiconsIcon } from '@hugeicons/react';
import { RotateClockwiseIcon } from '@hugeicons/core-free-icons';
import CModal from '../../../../components/CModal';
import { PaginatedTransactions, Transaction } from '@next-is-free-monorepo/next-is-free-types';
import { apiV1 } from '../../../../utils/apiClient';
import StaffMemberSelect from '../../../../components/StaffMemberSelect';

export const Route = createFileRoute('/_authenticated/transactions/')({
  component: RouteComponent,
  pendingComponent: PageLoader
});


function RouteComponent() {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | undefined>();
  const [selectedSalesPointId, setSelectedSalesPointId] = useState('');
  const [selectedStaffMemberId, setSelectedStaffMemberId] = useState('');
  const [cancelConfirmMessage, setCancelConfirmMessage] = useState<undefined | string>(undefined);
  const [transactionToCancelId, setTransactionToCancelId] = useState<undefined | string>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const shopAttrs = useShopAttrs();

  const staff = useSuspenseQuery(staffQueryOptions());
  const salesPoints = useSuspenseQuery(salesPointsQueryOptions());

  const transactions = useInfiniteQuery(paginatedTransactionsQueryOptions(selectedDate, selectedSalesPointId, selectedStaffMemberId));

  const columns = [
    {
      title: 'Data e ora',
      dataIndex: 'created_at',
      key: 'hour',
      render: (value: string) => (
        <p style={{ textTransform: 'capitalize' }}>
          {dayjs(value).locale('it').format('DD/MM/YYYY HH:mm')}
        </p>
      )
    },
    {
      title: 'Nome',
      dataIndex: 'goal_name',
      key: 'goal_name',
      render: (value: string) => (
        <p style={{ textTransform: 'capitalize' }}>
          {value ? value : '-'}
        </p>
      )
    },
    {
      title: 'Punto Vendita',
      dataIndex: 'sales_point_name',
      key: 'sales_point_name',
      hidden: salesPoints.data.length === 1,
    },
    {
      title: 'Staff',
      dataIndex: 'staff_member_display_name',
      key: 'staff_member_display_name',
      hidden: staff.data.length === 0,
      render: (value: string) => value ?? 'Admin'
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (value: number) =>
        value === 0 ? (
          <p
            style={{
              backgroundColor: green[2],
              width: 'fit-content',
              padding: '1px 10px',
              borderRadius: 4,
              color: green[7]
            }}
          >
            Assegnati
          </p>
        ) : value === 1 ? (
          <p
            style={{
              backgroundColor: blue[2],
              width: 'fit-content',
              padding: '1px 10px',
              borderRadius: 4,
              color: blue[7]
            }}
          >
            Traguardo
          </p>
        ) : (
          <p
            style={{
              backgroundColor: purple[2],
              width: 'fit-content',
              padding: '1px 10px',
              borderRadius: 4,
              color: purple[7]
            }}
          >
            Buono sconto
          </p>
        )
    },
    {
      title: 'Punti',
      dataIndex: 'points',
      key: 'points'
    },
    {
      title: 'Importo',
      dataIndex: 'euro',
      key: 'euro',
      render: (value: number) => <p>{value ? value + '€' : '-'}</p>
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value: string, record: Transaction) => (<CancelButton transaction={record} />)
    }
  ];

  function CancelButton({ transaction }: { transaction: Transaction }) {

    if (transaction.cancelled) {
      return <p className={'bg-slate-100'}
                style={{
                  width: 'fit-content',
                  padding: '1px 10px',
                  borderRadius: 4
                }}>
        Annullata
      </p>;
    }

    if (transaction.type === 2 || dayjs(transaction.created_at).isBefore(dayjs().subtract(24, 'hours'))) {
      return <div />;
    }

    if (transaction.type === 2) {
      return <div />;
    }

    const confirmMessages = [`Al cliente verranno sottratti ${transaction.points} punti`, `Al cliente verranno aggiunti ${transaction.points} punti`];

    return (
      <>
        <Button onClick={() => {
          setCancelConfirmMessage(confirmMessages[transaction.type]);
          setTransactionToCancelId(transaction.id);
        }} style={{ borderRadius: 30 }}><IconRotate2 size={15} /></Button>
      </>
    );
  }

  const disabledDate: any = (current: any) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  return (
    <div className={'p-4'}>
      <CModal open={!!cancelConfirmMessage && !!transactionToCancelId}
              onClose={() => setCancelConfirmMessage(undefined)} icon={<IconRotate2 />}
              mutationOptions={{
                mutationFn: async () => await apiV1.put(`/shop/transactions/${transactionToCancelId}/cancel`, {}),
                onSuccess: () => setCancelConfirmMessage(undefined),
                onSettled: () => queryClient.invalidateQueries({ queryKey: ['transactions'] })
              }}
              color={'red'} title={'Annullare questa transazione?'}
              text={cancelConfirmMessage} />
      <Flex
        gap={10}
        wrap={'wrap'}
        style={{ marginBottom: 30 }}
        justify={'space-between'}
      >
        <h1 className={'font-bold text-xl'}>Transazioni</h1>
        <Flex gap={5} wrap={'wrap'}>
          <Suspense fallback={<Skeleton.Input style={{ width: 200 }} active />}>
            <StaffMemberSelect size={'large'}
                               selectedStaffMemberId={selectedStaffMemberId}
                               setSelectedStaffMemberId={setSelectedStaffMemberId}
            />
          </Suspense>
          <Suspense fallback={<Skeleton.Input style={{ width: 200 }} active />}>
            <SalesPointsSelect size={'large'}
                               selectedSalesPointId={selectedSalesPointId}
                               setSelectedSalesPointId={setSelectedSalesPointId}
            />
          </Suspense>
          <DatePicker
            style={{ minWidth: 200, flex: 1 }}
            allowClear
            format="DD/MM/YYYY"
            disabledDate={disabledDate}
            onChange={setSelectedDate}
            defaultValue={selectedDate}
            placeholder={'Seleziona una data'}
          />
          <Button style={{ flex: 1 }} disabled={!shopAttrs.data.isVisible}
                  onClick={() => navigate({ to: '/transactions/capture' })} icon={<IconScan />} type={'primary'}
                  size={'large'}>
            Scansiona QR code
          </Button>
        </Flex>
      </Flex>
      <Table
        pagination={false}
        rowKey={'created_at'}
        style={{ overflow: 'hidden' }}
        size={'small'}
        loading={transactions.isLoading}
        dataSource={transactions.data?.pages.map((page)=>page.transactions).flat()}
        columns={columns}
      />
      {transactions.hasNextPage &&
        <div className={'flex justify-end mt-5'}>
          <Button onClick={() => transactions.fetchNextPage()} icon={<IconArrowDown size={14} />}>Carica altre</Button>
        </div>
      }
    </div>
  );
}
