import { ClockCircleOutlined, DeleteOutlined, GiftFilled, InboxOutlined, SendOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { supabase } from '../utils/supabase';
import { bucketUrl } from '../utils/urls';
import {
  Notification,
  notificationsStatusColors,
  notificationsStatusLabels
} from '@next-is-free-monorepo/next-is-free-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiV1 } from '../utils/apiClient';
import useShopAttrs from '../utils/hooks/useShopAttrs';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { IconArchive, IconGiftFilled } from '@tabler/icons-react';

const NotificationCard = ({ item, selected, onClick }: {
  item: Notification,
  selected: boolean,
  onClick: () => void
}) => {

  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const shopsAttrs = useShopAttrs();

  async function deleteNotificationFunction() {
    await apiV1.delete(`/shop/notifications/${item.id}`);
    if (item.image) {
      const { error: deleteImageError } = await supabase
        .storage
        .from('main')
        .remove([shopsAttrs.data.id + '/' + item.id + '.jpeg']);

      if (deleteImageError) {
        throw deleteImageError;
      }
    }
    await queryClient.invalidateQueries({ queryKey: ['notifications'] });
    setModalOpen(false);
  }

  const deleteNotification = useMutation({
    mutationKey: ['delete-notification'],
    mutationFn: async () => await deleteNotificationFunction()
  });


  const image = item.image ? bucketUrl + shopsAttrs.data.id + '/' + item.id + '.jpeg' : bucketUrl + shopsAttrs.data.id + '/logo.png';


  const items = [
    {
      key: '1',
      label: (
        <a onClick={() => setModalOpen(true)}>
          Elimina
        </a>
      ),
      danger: true,
      icon: <DeleteOutlined />
    }
  ];

  return (
    <>
      <div onClick={onClick}
           className={`overflow-hidden p-4 border-b-[1px] cursor-pointer ${!selected ? 'bg-white' : 'bg-slate-50'} ${selected && 'border-r-4 border-r-primary'} `}>
        <div className={'flex gap-3 w-full justify-between items-center'}>
          <img alt={'notification'} className={'aspect-square rounded-md w-[60px] object-cover'} src={image} width={80}
               height={80} />
          <div className={'flex-1'}>
            <p className={'font-bold line-clamp-2'}>{item.title}</p>
            <p className={`line-clamp-1 text-slate-500`}>{item.text}</p>
          </div>
        </div>
        <div className={'mt-2 flex items-center gap-1'}>
          <Tag
            className={`${notificationsStatusColors[item.status].bg} ${notificationsStatusColors[item.status].text} rounded-3xl m-0`}
            bordered={false}>{notificationsStatusLabels[item.status]}</Tag>
          {item?.widgets?.includes('one_time_promo') &&
            <div className={'bg-blue-200 text-blue-600 flex justify-center items-center h-[22px] w-[22px] rounded-full'}><IconGiftFilled width={13}/></div>
          }
          <Tag className={'bg-white rounded-3xl border-slate-300 text-slate-600 m-0'}
               icon={item.status === 'sent' ? <SendOutlined /> : item.status === 'pending' ? <ClockCircleOutlined /> :
                 <InboxOutlined />}>{item.status === 'sent' ? dayjs(item.created_at).format('DD/MM/YY - HH:mm') : item.status === 'pending' ?  dayjs(item.schedule_time).format('DD/MM/YY - HH:mm') :  dayjs(item.archived_at).format('DD/MM/YY - HH:mm')}</Tag>
        </div>
      </div>
    </>);
};
export default NotificationCard;
