import { Locales } from '../types/types';

export const UIErrorI18nKeyValues = ['ok', 'qrCodeAlreadyAssociated', 'taken'] as const;

export type UIMessageI18nKey = typeof UIErrorI18nKeyValues[number]

type TranslationResources = {
  [lang in Locales]: {
    [key in UIMessageI18nKey]: string;
  };
}

export const uiMessagesTranslations : TranslationResources = {
  en: {
    ok: 'ok',
    qrCodeAlreadyAssociated: 'QR Code already associated',
    taken: 'taken',
  },
  it: {
    ok: 'ok',
    qrCodeAlreadyAssociated: 'QR Code già associato',
    taken: 'già preso',
  }
}
