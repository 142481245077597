import { Button, DatePicker, Modal, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useState } from 'react';

interface NotificationScheduleModalProps {
}

const NotificationScheduleModal = ({ open, onSubmit, loading, onCancel, defaultValue = dayjs() }: {
  open: boolean,
  onSubmit: (scheduleTime: Dayjs) => void,
  loading: boolean,
  onCancel: () => void,
  defaultValue: Dayjs | undefined
}) => {

  const roundToNearestHalfHour = (time: Dayjs): Dayjs => {
    const minutes = time.minute();
    const roundedMinutes = minutes < 15 ? 0 : minutes < 45 ? 30 : 60;
    return time.minute(roundedMinutes).second(0);
  };

  const [date, setDate] = useState<Dayjs>(defaultValue ?? dayjs());
  const [time, setTime] = useState<Dayjs>(roundToNearestHalfHour(defaultValue ?? dayjs()));

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = () => ({
    disabledHours: () => defaultValue.date() === dayjs().date() ? range(0, 24).splice(0, dayjs().hour()) : [],
  });

  const disabledTime = () => ({
    disabledHours: () => date.date() === dayjs().date() ? range(0, 24).splice(0, dayjs().add(1, 'h').hour()) : [],
  })

  function onSchedule() {
    onSubmit(dayjs(date).hour(time.hour()).minute(time.minute()));
  }

  return (
    <Modal footer={<div className={'flex gap-2'}>
      <Button disabled={loading} className={'flex-1 rounded-md'}>Annulla</Button>
      <Button loading={loading} className={'flex-1 rounded-md'} type={'primary'} onClick={onSchedule}>Programma</Button>
    </div>} style={{ maxWidth: 300 }} onCancel={onCancel} centered open={open}>
      <div className={'pt-5 pb-2'}>
        <p className={'font-semibold'}>Data</p>
        <DatePicker disabled={loading} disabledDate={(current) => {
          return current && current < dayjs().startOf('day');
        }} disabledTime={disabledDate} value={date}
                    onChange={(e) => setDate(e)} style={{ width: '100%' }}
                    format={'DD/MM/YYYY'} />
        <p className={'font-semibold mt-2'}>Ora</p>
        <TimePicker value={time} onChange={setTime} format={'HH:mm'} className={'w-full'} minuteStep={30} disabled={loading} disabledTime={disabledTime}/>
        <p className={'text-gray-500 text-xs mt-2'}>Seleziona il giorno e l'ora d'invio della notifica.</p>
      </div>
    </Modal>);
};
export default NotificationScheduleModal;
