import { createFileRoute } from '@tanstack/react-router';
import { DatePicker, Flex, Segmented, Select, Skeleton, Tabs, TabsProps } from 'antd';

import dayjs from 'dayjs';
import TotalCustomersNumber from '../../../components/Analytics/TotalCustomersNumber';
import CustomersActiveNotifications from '../../../components/Analytics/CustomersActiveNotifications';
import NotificationsFrequency from '../../../components/Analytics/NotificationsFrequency';
import CustomersNumberTrend from '../../../components/Analytics/CustomersNumberTrend';
import AllocatedPointsTrend from '../../../components/Analytics/AllocatedPointsTrend';
import SpentPointsTrend from '../../../components/Analytics/SpentPointsTrend';
import GoalsRankHours from '../../../components/Analytics/GoalsRankHours';
import MainActivityHours from '../../../components/Analytics/MainActivityHours';
import NotificationsReadTime from '../../../components/Analytics/NotificationsReadTime';
import TotalNotificationsSent from '../../../components/Analytics/TotalNotificationsSend';
import { computed, signal } from '@preact/signals-react';
import PageLoader from '../../../microcomponents/PageLoader';
import { ReactNode, Suspense, useState } from 'react';
import Wrapper from '../../../components/Wrapper';
import SalesPointsSelect from '../../../components/SalesPointsSelect';
import React from 'react';
import { HugeiconsIcon } from '@hugeicons/react';
import { GiftFreeIcons, Megaphone03FreeIcons, StarFreeIcons } from '@hugeicons/core-free-icons';
import LoyaltyProgramTab from '../../../components/Analytics/tabs/LoyaltyProgramTab';
import NotificationsTab from '../../../components/Analytics/tabs/NotificationsTab';
import OneTimePromosTab from '../../../components/Analytics/tabs/OneTimePromosTab';


export const timeSpan = signal<'year' | 'month' | 'week'>('month');
export const endDate = signal(dayjs());
export const startingDate = computed(() => {
  switch (timeSpan.value) {
    case 'year':
      return endDate.value.subtract(1, 'year');

    case 'month':
      return endDate.value.subtract(1, 'month');

    case 'week':
      return endDate.value.subtract(1, 'week');
  }
});



export const Route = createFileRoute('/_authenticated/analytics')({
  component: Analytics,
  pendingComponent: PageLoader
});

function Analytics() {

  const [selectedSalesPointId, setSelectedSalesPointId] = useState('');

  function TabLayout({children}: {children: ReactNode}) {
    return(
      <div className={'px-5 pb-10'}>
        <Flex
          wrap={'wrap'}
          gap={10}
          style={{ marginBottom: 30 }}
          justify={'space-between'}
        >
          <div>
            <Suspense fallback={<Skeleton.Input style={{ width: 200 }} active />}>
              <SalesPointsSelect selectedSalesPointId={selectedSalesPointId}
                                 setSelectedSalesPointId={setSelectedSalesPointId} />
            </Suspense>
          </div>
          <Flex wrap={'wrap'} gap={8}>
            <DatePicker
              allowClear={false}
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
              style={{ backgroundColor: 'white' }}
              onChange={(date) => (endDate.value = date)}
              defaultValue={endDate.value}
            />
            <div style={{ flex: 1 }}>
              <Segmented
                options={[
                  { value: 'year', label: 'Un anno' },
                  {
                    value: 'month',
                    label: 'Un mese'
                  },
                  { value: 'week', label: 'Una settimana' }
                ]}
                value={timeSpan.value}
                onChange={(e: any) => {
                  timeSpan.value = e;
                }}
              />
            </div>
          </Flex>
        </Flex>
        {children}
      </div>
    )
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Programma fedeltà',
      children: <TabLayout><LoyaltyProgramTab selectedSalesPointId={selectedSalesPointId}/></TabLayout>,
      /* icon: <HugeiconsIcon icon={StarFreeIcons} className={'inline-block'} size={12} strokeWidth={1.5}/>*/
    },
    {
      key: '2',
      label: 'Notifiche',
      children: <TabLayout><NotificationsTab selectedSalesPointId={selectedSalesPointId}/></TabLayout>,
      /*    icon: <HugeiconsIcon icon={Megaphone03FreeIcons} className={'inline-block'} size={12} strokeWidth={1.5}/>*/
    },
    {
      key: '3',
      label: 'Buoni sconto',
      children: <TabLayout><OneTimePromosTab selectedSalesPointId={selectedSalesPointId}/></TabLayout>,
      /*icon: <HugeiconsIcon icon={GiftFreeIcons} className={'inline-block'} size={12} strokeWidth={1.5}/>*/
    },
  ];

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  return (
    <div>
      <div className={'p-5'}>
        <h1 className={'text-xl font-semibold mb-5'}>
          Analitiche
        </h1>
        <div className={'grid grid-cols-1 gap-5 mb-5 flex-wrap md:grid-cols-2 lg:grid-cols-4'}>
          <Suspense fallback={<Wrapper mh={105} loading />}>
            <TotalCustomersNumber />
          </Suspense>
          {/*<div className={'h-[30px bg-slate-200 w-[1px]'}/>*/}
          <Suspense fallback={<Wrapper loading />}>
            <TotalNotificationsSent />
          </Suspense>
          {/*<div className={'h-[30px bg-slate-200 w-[1px]'}/>*/}
          <Suspense fallback={<Wrapper loading />}>
            <CustomersActiveNotifications />
          </Suspense>
         {/* <div className={'h-[30px bg-slate-200 w-[1px]'}/>*/}
          <Suspense fallback={<Wrapper loading />}>
            <NotificationsFrequency />
          </Suspense>
        </div>
      </div>

      <Tabs tabBarExtraContent={{left: <div className={'w-5'}/>}} defaultActiveKey="1" items={items} />
      {/*<Flex
        wrap={'wrap'}
        gap={10}
        style={{ marginBottom: 30 }}
        justify={'space-between'}
      >
        <h1 className={'font-medium text-xl'}>Analitiche</h1>
        <Flex wrap={'wrap'} gap={8}>
          <Suspense fallback={<Skeleton.Input style={{ width: 200 }} active />}>
            <SalesPointsSelect selectedSalesPointId={selectedSalesPointId}
                               setSelectedSalesPointId={setSelectedSalesPointId} />
          </Suspense>
          <DatePicker
            allowClear={false}
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            style={{ backgroundColor: 'white' }}
            onChange={(date) => (endDate.value = date)}
            defaultValue={endDate.value}
          />
          <div style={{ flex: 1 }}>
            <Segmented
              options={[
                { value: 'year', label: 'Un anno' },
                {
                  value: 'month',
                  label: 'Un mese'
                },
                { value: 'week', label: 'Una settimana' }
              ]}
              value={timeSpan.value}
              onChange={(e: any) => {
                timeSpan.value = e;
              }}
            />
          </div>
        </Flex>
      </Flex>
      <div className={'smallAnalyticsGrid'}>
        <Suspense fallback={<Wrapper mh={105} loading />}>
          <TotalCustomersNumber />
        </Suspense>
        <Suspense fallback={<Wrapper loading />}>
          <CustomersActiveNotifications />
        </Suspense>
        <Suspense fallback={<Wrapper loading />}>
          <TotalNotificationsSent />
        </Suspense>
        <Suspense fallback={<Wrapper loading />}>
          <NotificationsFrequency />
        </Suspense>
      </div>
      <div className={'analyticsGrid'}>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <CustomersNumberTrend />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <AllocatedPointsTrend selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <SpentPointsTrend selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <GoalsRank selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <MainActivityHours selectedSalesPointId={selectedSalesPointId} />
        </Suspense>
        <Suspense fallback={<Wrapper loading mh={460} />}>
          <NotificationsReadTime />
        </Suspense>
      </div>*/}
    </div>
  );
}
