import { CustomerQrCodeType, QrCodePrefixes } from '@next-is-free-monorepo/next-is-free-types';
import { z } from 'zod';

function extractMatchingSuffix(input: string): string | null {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  // Ensure input is a valid string
  if (input.length === 0) {
    return null;
  }

  // Split the string by '%'
  const parts: string[] = input.split('%');

  if (parts.length < 2) {
    return null; // Not enough parts to match the pattern
  }

  let uuid: string | undefined = parts.pop(); // Last part
  let prefix: string | undefined = parts.pop(); // Second-to-last part

  // Validate that the last part is a UUID
  if (!uuid || !uuidRegex.test(uuid)) {
    return null;
  }

  // Case: add%uuid
  if (prefix === 'add') {
    return `add%${uuid}`;
  }

  // Case: 0%uuid
  if (QrCodePrefixes.safeParse(prefix).success) {
    return `${prefix}%${uuid}`;
  }

  // Case: oneTimePromo%uuid%uuid
  if (prefix && uuidRegex.test(prefix)) {
    const precedingPart: string | undefined = parts.pop();
    if (precedingPart === 'oneTimePromo') {
      return `oneTimePromo%${prefix}%${uuid}`;
    }
  }

  return null; // No match found
}


export async function checkCustomerQrCode(qrCode: string): Promise<{type: CustomerQrCodeType, userId: string, clean: string}>{

  const cleanQrCode = extractMatchingSuffix(qrCode.replace(/'/g, '-'));

  console.log(qrCode);

  if(!cleanQrCode){
    throw new Error();
  }

  const qrCodeArray = cleanQrCode.split('%');
  try{
    await z.string().uuid().parseAsync(qrCodeArray[1]);
  }catch (e){
    throw new Error();
  }


  if (qrCodeArray[0] === 'add') {
    return {type: 'addPoints', userId: qrCodeArray[1], clean: cleanQrCode};
  }else if(qrCodeArray[0] === 'oneTimePromo'){
    return {type: 'oneTimePromo', userId: qrCodeArray[2], clean: cleanQrCode};
  }

  try {
    await QrCodePrefixes.parseAsync(qrCodeArray[0]);
    return {type: 'goal', userId: qrCodeArray[1], clean: cleanQrCode};
  }catch (e){
    throw new Error();
  }

}
