import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import Wrapper from '../../../components/Wrapper'
import { Button } from 'antd'
import { DownloadOutlined, WarningOutlined } from '@ant-design/icons'
import { lazy, Suspense } from 'react'
import { useState } from 'react'
import CModal from '../../../components/CModal'
import { useSuspenseQuery } from '@tanstack/react-query'
import { salesPointsQueryOptions } from '../../../utils/queryOptions'
import Flyer from '../../../components/resources/GoalsPoster';
import GoalsPoster from '../../../components/resources/GoalsPoster';

const MyDocument = lazy(() => import('../../../pdf-templates/SalesPointQrCodesPDF'))

export const Route = createFileRoute('/_authenticated/resources')({
  component: RouteComponent,
})

/*
const MyDocument = () => (
  <PDFDownloadLink className={'flex-1'} document={<Document><Page size={[708.661, 992.126]}>
    <View style={{ width: '100%', height: '100%' }}>
      <Image src={QrCodesTemplate}
             style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }} />
    </View>
  </Page>
  </Document>} fileName={`${new Date().toLocaleDateString('it-IT', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Europe/Rome'
  })}.pdf`}>
    <Button>scaca</Button>
  </PDFDownloadLink>
);
*/

function RouteComponent() {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const salesPoints = useSuspenseQuery(salesPointsQueryOptions())


  // @ts-ignore
  return (
    <div className={'max-w-lg m-auto mt-10'}>
      <div>
        <h2 className={'font-semibold text-lg'}>Qr codes</h2>
        <p className={'text-slate-500 text-sm mb-2'}>
          Codici qr associati ai tuoi punti vendita
        </p>
        {salesPoints.data.map((point) => (
          <Wrapper key={point.id} p={10} mt={5}>
            <div className={'flex justify-between items-center'}>
              <p className={'font-medium'}>{point.name}</p>
              <div>
                {point.associated_qrcode ? (
                  <Suspense fallback={<Button>Caricamento...</Button>}>
                    <MyDocument qrCode={point.associated_qrcode}/>
                  </Suspense>
                ) : (
                  <Button disabled>Qr code non associato</Button>
                )}
              </div>
            </div>
          </Wrapper>
        ))}
      </div>
      <div>
        <h2 className={'font-semibold text-lg mt-5'}>Poster Traguardi</h2>
        <p className={'text-slate-500 text-sm mb-2'}>
          Un poster in formato A4 con il riepilogo dei traguardi offerti
        </p>
        <div>
          {salesPoints.data.map((point) => (
            <Wrapper key={point.id} p={10} mt={5}>
              <div className={'flex justify-between items-center'}>
                <p className={'font-medium'}>{point.name}</p>
                <div>
                  {point.associated_qrcode ? (
                    <GoalsPoster qrCode={point.associated_qrcode}/>
                  ) : (
                    <Button disabled>Qr code non associato</Button>
                  )}
                </div>
              </div>
            </Wrapper>
          ))}
        </div>
      </div>
     {/* <div>
        <h2 className={'font-semibold text-lg mt-5'}>Poster traguardi</h2>
        <p className={'text-slate-500 text-sm mb-2'}>
          Un poster in formato A4 con il riepilogo dei traguardi offerti con il
          tuo programma fedeltà
        </p>
        <Wrapper p={10}>
          <div className={'flex justify-between items-center'}>
            <p>Poster per il tuo negozio</p>
            <div>
              <Suspense fallback={<Button>Caricamento...</Button>}>

              </Suspense>
            </div>
          </div>
        </Wrapper>
      </div>*/}
      {/*<div>
        <h2 className={'font-semibold text-lg mt-5'}>
          Risorse del brand Next is Free
        </h2>
        <p className={'text-slate-500 text-sm mb-2'}>
          Il logo di Next is Free in vari formati.{' '}
        </p>
        <Wrapper p={10}>
          <div className={'flex justify-between items-center'}>
            <p>Logo Next is Free</p>
            <Button
              onClick={() => setConfirmModalOpen(true)}
              icon={<DownloadOutlined />}
            >
              Scarica
            </Button>
          </div>
          Aggiungi modal con disclamer sull'utilizzo dei loghi
        </Wrapper>
      </div>*/}
      <CModal
        color={'red'}
        confirmButtonText={'Scarica'}
        mutationOptions={{}}
        onClose={() => setConfirmModalOpen(false)}
        icon={<WarningOutlined />}
        open={confirmModalOpen}
        title={"Norme sull'utilizzo del logo"}
        text={
          "Attenzione – Scaricando il logo di Next Is Free, accetti i seguenti termini di utilizzo: il logo non può essere modificato in alcun modo, inclusi colore, proporzioni, elementi grafici o tipografici. Non può essere utilizzato in contesti che possano risultare denigranti, offensivi o dannosi per l'immagine di Next Is Free. Qualsiasi uso improprio potrà comportare l'adozione di provvedimenti legali. Se accetti questi termini, puoi procedere con il download."
        }
      />
    </div>
  )
}
