import ContainerBlock from './ContainerBlock';
import HorizontalRankBars from './charts/HorizontalRankBars';
import { useSuspenseQuery } from '@tanstack/react-query';
import { readNotificationsQueryOptions, transactionsQueryOptions } from '../../utils/queryOptions';
import { endDate, startingDate } from '../../src/routes/_authenticated/analytics';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import NotificationPreview from '../NotificationPreview';
import NotificationPreviewModal from '../notifications/NotificationPreviewModal';

interface MostReadNotificationsProps {
}

const MostReadNotifications = () => {

  const [seeAllModalOpen, setSeeAllModalOpen] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<string | undefined>(undefined);

  const readNotifications = useSuspenseQuery(readNotificationsQueryOptions(startingDate.value, endDate.value));

  const groupedReadsById = readNotifications.data.reduce((acc, read) => {
    const existingRead = acc.find(item => item.id === read.notification_id);
    if (existingRead) {
      existingRead.value++;
    } else {
      acc.push({ index: `${read.title} (${read.notification_id})`, value: 1, id: read.notification_id, title: read.title });
    }
    return acc;
  }, [] as Array<{ index: string, value: number, id: string, title: string }>);

  // sort by value ad return max 10
  groupedReadsById.sort((a, b) => a.value - b.value);

  return (
    <>
      <ContainerBlock noData={groupedReadsById.length < 1} title={'Notifiche più lette'}>
        <div />
        <HorizontalRankBars
          data={groupedReadsById.slice(-10)}
          onBarClick={(value) => setSelectedNotificationId(value.data.id)}
        />
        {groupedReadsById.length > 10 &&  <p onClick={()=>setSeeAllModalOpen(true)} style={{ marginLeft: 90 }} className={'underline cursor-pointer text-sm text-slate-500'}>Vedi tutte...</p>}
      </ContainerBlock>
      <Modal width={'100%'} style={{maxWidth: 700}} open={seeAllModalOpen} onCancel={()=>setSeeAllModalOpen(false)} footer={null}>
          <div style={{width: '100%', height: groupedReadsById.length * 30}}>
            <p className={'font-semibold'}>Notifiche più lette</p>
            <HorizontalRankBars
              data={groupedReadsById}
              onBarClick={(value) => setSelectedNotificationId(value.data.id)}
            />
          </div>
      </Modal>
      {
        selectedNotificationId &&
        <NotificationPreviewModal id={selectedNotificationId} onCancel={()=>setSelectedNotificationId(undefined)}/>
      }
    </>
  );
};
export default MostReadNotifications;
