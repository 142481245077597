import { IconCalendar, IconList } from '@tabler/icons-react';
import * as React from 'react';
import { Link } from '@tanstack/react-router';

interface NotificationsPageNavBarProps {
}

const NotificationsPageNavBar = ({selected, selectedNotificationId}:{selected: 'list' | 'calendar', selectedNotificationId: undefined | string}) => {
  return (
    <div className={`h-[50px] w-full border-b-[1px] flex gap-4 justify-center fixed bg-white top-[45px] z-[90] md:static ${selectedNotificationId && 'hidden md:flex'}`}>
      <Link to={'/notifications'} search={{view: 'list'}} className={`flex items-center gap-1 font-medium ${selected != 'list' && 'text-slate-500'}`}>
        <IconList size={16} />
        <p className={`${selected === 'list' && 'underline'} decoration-primary decoration-2 underline-offset-2`}>Lista</p>
      </Link>
      <Link to={'/notifications'} search={{view: 'calendar'}} className={`flex items-center gap-1 font-medium ${selected != 'calendar' && 'text-slate-500'}`}>
        <IconCalendar size={16} />
        <p className={`${selected === 'calendar' && 'underline'} decoration-primary decoration-2 underline-offset-2`}>Calendario</p>
      </Link>
    </div>
  );
};
export default NotificationsPageNavBar;
