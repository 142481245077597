import SimpleStatistic from './charts/SimpleStatistic';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { green, red } from '@ant-design/colors';
import { useSuspenseQuery } from '@tanstack/react-query';
import { notificationsCountQueryOptions, notificationsQueryOptions } from '../../utils/queryOptions';
import React from 'react';

const NotificationsFrequency = () => {

  const notifications = useSuspenseQuery(notificationsQueryOptions());

  const notificationsCount = useSuspenseQuery(notificationsCountQueryOptions());

  const [noData, setNoData] = useState(true);
  const [frequency, setFrequency] = useState<{
    value: number,
    suffix: string,
    feedback: { tooltipText: string, selectedStep: number }
  }>({
    value: 0,
    suffix: '',
    feedback: { selectedStep: 1, tooltipText: '' }
  });

  useEffect(() => {
    if (notifications.data.length > 0) {
      const latestIndex = notifications.data.length - 1;
      setFrequency(calculateFrequency(notifications.data[latestIndex].created_at));
    }
  }, []);


  function calculateFrequency(oldestNotificationDate: string) {

    const daysDifference = dayjs().diff(oldestNotificationDate, 'days');

    if (daysDifference < 31) {
      setNoData(true);
      return {
        value: 0,
        suffix: '',
        feedback: { selectedStep: 1, tooltipText: '' }
      };
    } else {
      setNoData(false);
    }

    const dayFrequency = notificationsCount.data / daysDifference;

    if (dayFrequency >= 1) {
      return {
        value: dayFrequency,
        suffix: 'al giorno',
        feedback: {
          selectedStep: 3,
          tooltipText:
            'La frequenza di invio notifiche è troppo alta, i clienti potrebbero decidere di disattivare le notifiche del tuo negozio. (Frequenza ideale tra 2 e 10 al mese)'
        }
      };
    } else {
      const monthFrequency = dayFrequency * 30.44; //average number of days in a month;

      let feedback;

      if (monthFrequency <= 2) {
        feedback = {
          selectedStep: 1,
          tooltipText:
            'La frequenza di invio notifiche è troppo bassa, i clienti potrebbero dimenticarsi del tuo negozio. (Frequenza ideale tra 2 e 8 al mese)'
        };
      } else if (monthFrequency < 8 && monthFrequency > 2) {
        feedback = {
          selectedStep: 2,
          tooltipText:
            'La frequenza di invio notifiche è nella media. Assicurati che questo valore rimanga tra i 2 e i 8 al mese.'
        };
      } else {
        feedback = {
          selectedStep: 3,
          tooltipText:
            'La frequenza di invio notifiche è troppo alta, i clienti potrebbero decidere di disattivare le notifiche del tuo negozio. (Frequenza ideale tra 2 e 8 al mese)'
        };
      }

      return { value: monthFrequency, suffix: 'al mese', feedback };
    }
  }

  return (
    <SimpleStatistic
      stepsFeedbackBadge={{
        mode: 'single',
        selectedStep: frequency.feedback.selectedStep,
        steps: [
          {
            color: 'yellow'
          },
          { color: 'green' },
          { color: 'red' }
        ]
      }}
      noData={noData}
      title={'Frequenza invio notifiche'}
      value={frequency.value}
      suffix={frequency.suffix}
      disableFormatter
      tooltipText={frequency.feedback.tooltipText}
    />
  );
};
export default NotificationsFrequency;
