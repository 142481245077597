import { HugeiconsIcon } from '@hugeicons/react';
import { Alert01FreeIcons, KeyIcon } from '@hugeicons/core-free-icons';
import { Alert, Button, Form, Input } from 'antd';
import CModal from '../CModal';
import * as React from 'react';
import { MutationOptions, UseMutationOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import CopyText from '../../microcomponents/CopyText';
import { COLORS } from '@next-is-free-monorepo/globals';
import { ShopStaffCreateResponse } from '@next-is-free-monorepo/next-is-free-types';
import { apiV1 } from '../../utils/apiClient';

interface NewStaffMemberModalProps {
  open: boolean,
  setOpen: (open: boolean) => void
}

const NewStaffMemberModal = ({ open, setOpen }: NewStaffMemberModalProps) => {
  const [displayName, setDisplayName] = useState<string>('');
  const [results, setResults] = useState<undefined | ShopStaffCreateResponse>(undefined);

  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<ShopStaffCreateResponse> = {
    mutationKey: ['staff', 'create'],
    mutationFn: async () => {
      return await apiV1.post<ShopStaffCreateResponse>('/shop/staff', { display_name: displayName });
    },
    onSuccess: (results) => {
      setResults(results);
    },
    onSettled() {
      queryClient.invalidateQueries({ queryKey: ['staff'] });
    }
  };

  return (
    <CModal hideConfirmButton={!!results} cancelButtonText={results ? 'Chiudi' : 'Annulla'} color={'blue'} open={open}
            confirmButtonText={'Crea'} onClose={() => {
      setOpen(false);
      setResults(undefined);
      setDisplayName('');
    }}
            icon={<HugeiconsIcon icon={KeyIcon} />} mutationOptions={mutationOptions}
    >
      {!results ?
        <Form layout={'vertical'}>
          <Form.Item label={'Nome'}>
            <Input value={displayName} onChange={(e) => {
              setDisplayName(e.target.value);
            }} placeholder={'Mario Rossi'} />
          </Form.Item>
        </Form>
        :
        <div className={'mt-5'}>
          <div className={'flex flex-col gap-4'}>
            <CopyText label={'Username'} text={results.username} />
            <CopyText label={'Password'} text={results.password} />
          </div>
          <div className={'flex gap-2 items-center mt-4'}>
            <p className={'leading-[1.2] text-slate-500'}>Assicurati di salvare la password ora perché non sarai in
              grado di rivederla in seguito.</p>
          </div>
        </div>
      }
    </CModal>
  );
};
export default NewStaffMemberModal;
