import * as React from 'react';
import {
  createFileRoute,
  redirect,
  SearchSchemaInput,
  useLocation,
  useBlocker,
  useCanGoBack,
  useRouter, useNavigate
} from '@tanstack/react-router';
import PageLoader from '../../../../microcomponents/PageLoader';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Input,
  message,
  Modal, Radio, Segmented,
  Select,
  Switch,
  TimePicker
} from 'antd';
import { useState } from 'react';
import { apiV1 } from '../../../../utils/apiClient';
import { supabase } from '../../../../utils/supabase';
import { compressImage } from '../../../../utils/imageCompression';
import { trackEvent } from '../../../../utils/Matomo/tracking_functions';
import {
  ArrowLeftOutlined,
  CalendarFilled,
  CalendarOutlined,
  ClockCircleOutlined,
  FacebookOutlined,
  GiftOutlined,
  InstagramOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined, PlusOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import ImageUploadInput from '../../../../components/ImageUploadInput';
import ConfirmButton from '../../../../components/ConfirmButton';
import PhonePreviewFrame from '../../../../components/PhonePreviewFrame';
import NotificationPreview from '../../../../components/NotificationPreview';
import useShopAttrs from '../../../../utils/hooks/useShopAttrs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CallToActionType, ctaDefaultLabel,
  ctaValueInputLabels, ctaValueValidation,
  NewNotification, NotificationWidget, ScheduleNotification
} from '@next-is-free-monorepo/next-is-free-types';
import { blue } from '@ant-design/colors';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import NotificationScheduleModal from '../../../../components/notifications/NotificationScheduleModal';
import Wrapper from '../../../../components/Wrapper';
import BgIcon from '../../../../microcomponents/BgIcon';
import { IconClock, IconGif, IconGift, IconInfinity, IconInfoCircle, IconX } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

const { TextArea } = Input;

export const Route = createFileRoute('/_authenticated/notifications/new')({
  component: RouteComponent,
  pendingComponent: PageLoader,
  validateSearch: (
    input: {
      scheduleTime?: string
    } & SearchSchemaInput
  ) => {
    return {
      scheduleTime: z.string().optional().parse(input?.scheduleTime)
    };
  }
});

function RouteComponent() {
  const canGoBack = useCanGoBack();
  const navigate = useNavigate();
  const router = useRouter();
  const safeGoBack = canGoBack ? useRouter().history.back : () => navigate({ to: '/notifications' });
  const shopAttrs = useShopAttrs();
  const queryClient = useQueryClient();
  const { scheduleTime: scheduleTimeFromSearch } = Route.useSearch();

  const [form] = Form.useForm();
  const [image, setImage] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [withOneTimePromotion, setWithOneTimePromotion] = useState(false);

  const title = Form.useWatch('title', form);
  const text = Form.useWatch('text', form);
  const ctaType: CallToActionType | 'no-cta' = Form.useWatch('cta-type', form);
  const ctaLabel: string = Form.useWatch('cta-label', form);
  const ctaValue: string = Form.useWatch('cta-value', form);
  const oneTimePromotionHasDeadline: boolean = Form.useWatch('oneTimePromotionHasDeadline', form);
  const oneTimePromoDescription: string = Form.useWatch('oneTimePromoDescription', form);

  type formValues = {
    title: string,
    text: string,
    'cta-type': CallToActionType | 'no-cta',
    'cta-label': string,
    'cta-value': string,
    oneTimePromotionHasDeadline: boolean,
    oneTimePromoDescription: string | undefined,
    oneTimePromotionValidFromDate: Dayjs | undefined,
    oneTimePromotionValidFromTime: Dayjs | undefined,
    oneTimePromotionValidToDate: Dayjs | undefined,
    oneTimePromotionValidToTime: Dayjs | undefined
  }

  function onFormChange(changedValues: any, values: any) {
    if (changedValues['cta-type']) {
      const ctaType: CallToActionType = changedValues['cta-type'];
      form.setFieldValue('cta-label', ctaDefaultLabel[ctaType]);
      form.setFieldValue('cta-value', '');
    }
  }

  async function sendNotificationFn(values: formValues) {


    const notification_id = uuidv4();

    if (image) {
      const compressedImage = await compressImage(image);
      const {
        data,
        error: uploadError
      } = await supabase.storage.from('main').upload(shopAttrs.data.id + '/' + notification_id + '.jpeg', compressedImage, { upsert: true });
      if (uploadError) {
        throw uploadError;
      }
      trackEvent('Notifications', 'Image Uploaded', values.title);
    }

    const widgets: NotificationWidget[] = [];

    let valid_from = undefined;
    let valid_to = undefined;

    if (oneTimePromotionHasDeadline && values.oneTimePromotionValidFromDate && values.oneTimePromotionValidFromTime && values.oneTimePromotionValidToDate && values.oneTimePromotionValidToTime) {
      valid_from = dayjs(values.oneTimePromotionValidFromDate).set('hour', values.oneTimePromotionValidFromTime.hour()).set('minute', values.oneTimePromotionValidFromTime.minute()).toISOString();
      valid_to = dayjs(values.oneTimePromotionValidToDate).set('hour', values.oneTimePromotionValidToTime.hour()).set('minute', values.oneTimePromotionValidToTime.minute()).toISOString();
    }

    if (withOneTimePromotion) {
      widgets.push({
        type: 'one_time_promo',
        options: {
          promo_id: uuidv4(),
          description: oneTimePromoDescription,
          valid_from,
          valid_to
        }
      });
    }

    const body: NewNotification = {
      id: notification_id,
      title: values.title,
      text: values.text,
      image: !!image,
      business_name: shopAttrs.data.business_name,
      shop_latest_update_at: shopAttrs.data.latest_update_at ?? '',
      call_to_actions: ctaType === 'no-cta' ? null : [{
        type: ctaType,
        value: ctaValue,
        label: ctaLabel
      }],
      widgets,
      brand_color: shopAttrs.data.brand_color
    };

    try {
      await apiV1.post('/shop/notifications', body);
    } catch (e) {
      console.log(e);
    }

  }

  const send = useMutation({
    mutationKey: ['notifications', 'send'],
    mutationFn: sendNotificationFn,
    onSuccess: () => {
      message.success('Notifica inviata con successo');
      safeGoBack();
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
    onError: (e) => {
      console.log(e);
      message.error('Errore nell\'invio della notifica');
    }
  });

  async function scheduleNotification(scheduleTime: Dayjs) {

    const values = form.getFieldsValue() as formValues;

    const notification_id = uuidv4();

    if (image) {
      const compressedImage = await compressImage(image);
      const {
        data,
        error: uploadError
      } = await supabase.storage.from('main').upload(shopAttrs.data.id + '/' + notification_id + '.jpeg', compressedImage, { upsert: true });
      if (uploadError) {
        throw uploadError;
      }
    }

    const widgets: NotificationWidget[] = [];

    let valid_from = undefined;
    let valid_to = undefined;

    if (oneTimePromotionHasDeadline && values.oneTimePromotionValidFromDate && values.oneTimePromotionValidFromTime && values.oneTimePromotionValidToDate && values.oneTimePromotionValidToTime) {
      valid_from = dayjs(values.oneTimePromotionValidFromDate).set('hour', values.oneTimePromotionValidFromTime.hour()).set('minute', values.oneTimePromotionValidFromTime.minute()).toISOString();
      valid_to = dayjs(values.oneTimePromotionValidToDate).set('hour', values.oneTimePromotionValidToTime.hour()).set('minute', values.oneTimePromotionValidToTime.minute()).toISOString();
    }

    if (withOneTimePromotion) {
      widgets.push({
        type: 'one_time_promo',
        options: {
          promo_id: uuidv4(),
          description: oneTimePromoDescription,
          valid_from,
          valid_to
        }
      });
    }

    const body: ScheduleNotification = {
      id: notification_id,
      title: title,
      text: text,
      image: !!image,
      business_name: shopAttrs.data.business_name,
      shop_latest_update_at: shopAttrs.data.latest_update_at ?? '',
      call_to_actions: ctaType === 'no-cta' ? null : [{
        type: ctaType,
        value: ctaValue,
        label: ctaLabel
      }],
      widgets,
      schedule_time: scheduleTime.toISOString(),
      brand_color: shopAttrs.data.brand_color
    };

    await apiV1.post('/shop/notifications/schedule', body);
  }

  const schedule = useMutation({
    mutationKey: ['notifications', 'schedule'],
    mutationFn: scheduleNotification,
    onSuccess: () => {
      message.success('Notifica programmata');
      safeGoBack();
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    }
  });


  return (
    <>
      <div style={{ marginBottom: 40 }}>
      </div>
      <div className={'customizePageW'}>
        <>
          <div style={{ width: '100%', maxWidth: 400 }}>
            <Button onClick={() => safeGoBack()} style={{ color: 'grey', marginBottom: 30 }}
                    icon={<ArrowLeftOutlined />}>Indietro</Button>
            <Form onValuesChange={onFormChange}
                  initialValues={{
                    'cta-type': 'no-cta',
                    oneTimePromotionHasDeadline: false,
                    oneTimePromotionValidToDate: dayjs(),
                    oneTimePromotionValidToTime: dayjs(),
                    oneTimePromotionValidFromDate: dayjs(),
                    oneTimePromotionValidFromTime: dayjs()
                  }} form={form}
                  onFinish={send.mutate} layout={'vertical'}>
              <Form.Item label={'Immagine'}>
                <ImageUploadInput setImage={setImage} image={image} setImagePreviewUrl={setImagePreviewUrl}
                                  imagePreviewUrl={imagePreviewUrl} objectFit={'cover'} />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Le notifiche devono avere un titolo' }]} label={'Titolo'}
                         name={'title'}>
                <Input onKeyDown={e => e.key === 'Enter' ? e.preventDefault() : null} showCount maxLength={100}
                       placeholder={'Titolo'} name={'title'} />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Le notifiche devono avere un testo' }]} label={'Testo'}
                         style={{ marginBottom: 30 }} name={'text'}>
                <TextArea showCount maxLength={2000} autoSize={{ minRows: 3, maxRows: 10 }}
                          placeholder={'Testo'}
                          name={'title'} />
              </Form.Item>
              <p className={'bg-blue-100 text-blue-600 w-fit py-1 px-2 mb-1 rounded-md'}>Novità!</p>
              {!withOneTimePromotion &&
                <Button className={'w-full mb-3'} icon={<PlusOutlined />} onClick={() => setWithOneTimePromotion(true)}>Aggiungi
                  Buono sconto</Button>
              }
              {withOneTimePromotion &&
                <Wrapper p={15} style={{ marginBottom: 30 }}>
                  <div className={'flex justify-between items-center mb-3'}>
                    <div className={'flex gap-2 items-center'}>
                      <BgIcon icon={<IconGift />} color={'blue'} />
                    </div>
                    <IconX onClick={() => setWithOneTimePromotion(false)} className={'text-slate-500 cursor-pointer'} />
                  </div>
                  <Form.Item rules={[{ required: true, message: 'Non può essere vuoto' }]}
                             name={'oneTimePromoDescription'} label={'Descrizione'}>
                    <Input placeholder={'Testo promozione'} />
                  </Form.Item>
                  <Form.Item name={'oneTimePromotionHasDeadline'} valuePropName={'checked'}>
                    <Segmented
                      block
                      options={[{
                        value: false,
                        label: 'Senza scadenza',
                        icon: <IconInfinity className={'inline-block'} size={14} />
                      }, {
                        value: true,
                        label: 'Con scadenza',
                        icon: <IconClock className={'inline-block'} size={14} />
                      }]}
                      value={oneTimePromotionHasDeadline}
                    />
                  </Form.Item>
                  {oneTimePromotionHasDeadline &&
                    <>
                      <p>Valida da</p>
                      <div className={'flex justify-between gap-2'}>
                        <Form.Item rules={[{ required: true, message: 'Non può essere vuoto' }]}
                                   name={'oneTimePromotionValidFromDate'} className={'flex-1'}>
                          <DatePicker format={'DD/MM/YY'} className={'w-full'}
                                      placeholder={'Data inizio'} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: 'Non può essere vuoto' }]}
                                   name={'oneTimePromotionValidFromTime'} className={'flex-1'}>
                          <TimePicker format={'HH:mm'} className={'w-full'}
                                      placeholder={'Ora d\'inizio'} />
                        </Form.Item>
                      </div>
                      <p>Valida fino a</p>
                      <div className={'flex justify-between gap-2'}>
                        <Form.Item rules={[{ required: true, message: 'Non può essere vuoto' }]}
                                   name={'oneTimePromotionValidToDate'} className={'flex-1'}>
                          <DatePicker format={'DD/MM/YY'} className={'w-full'}
                                      placeholder={'Data fine'} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: 'Non può essere vuoto' }]}
                                   name={'oneTimePromotionValidToTime'} className={'flex-1'}>
                          <TimePicker format={'HH:mm'} className={'w-full'}
                                      placeholder={'Ora fine'} />
                        </Form.Item>
                      </div>
                    </>
                  }
                  <p className={'text-sm text-slate-500 flex gap-1'}><IconInfoCircle size={16} className={'w-10'} />Una
                    promozione indipendente dai punti che il cliente può utilizzare una sola volta. La promozione può
                    essere disponibile per sempre o solo in un determinato periodo di tempo.</p>
                </Wrapper>
              }
              <Form.Item label={'Azione'} name={'cta-type'}
                         tooltip={'Seleziona un\'azione che l\'utente può eseguire direttamente dalla notifica, come visitare un sito, chiamare un numero o aggiungere un evento al calendario. Questo aiuta a rendere la notifica più interattiva ed efficace.'}
              >
                <Select>
                  <Select.Option value="call"><PhoneOutlined /> Chiama</Select.Option>
                  <Select.Option value="whatsapp"><WhatsAppOutlined /> WhatsApp</Select.Option>
                  <Select.Option value="url"><LinkOutlined /> Link</Select.Option>
                  <Select.Option value="instagram"><InstagramOutlined /> Instagram</Select.Option>
                  <Select.Option value="facebook"><FacebookOutlined /> Facebook</Select.Option>
                  <Select.Option value="email"><MailOutlined /> Email</Select.Option>
                  <Select.Option value="no-cta" style={{ color: 'gray' }}>Nessuna</Select.Option>
                </Select>
              </Form.Item>
              {ctaType && ctaType !== 'no-cta' &&
                <Form.Item name={'cta-label'} rules={[{ required: true, message: 'Non può essere vuoto' }]}
                           label={'Testo bottone'} required>
                  <Input placeholder={ctaDefaultLabel[ctaType]} />
                </Form.Item>
              }
              {ctaType && ctaType !== 'no-cta' &&
                <Form.Item name={'cta-value'} rules={[{
                  validator: (_, value) => {
                    if (ctaValueValidation[ctaType].schema.safeParse(value).success) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(ctaValueValidation[ctaType].errorMessage);
                    }
                  }
                }]} label={ctaValueInputLabels[ctaType]} required>
                  <Input placeholder={''} />
                </Form.Item>
              }
              <Form.Item style={{ marginTop: 30 }}>
                <ConfirmButton loading={send.isPending} onConfirm={() => form.submit()} text={'Invia la notifica'}
                               message={'La notifica verrà inviata a tutti i tuoi clienti e non può essere modificata, vuoi continuare?'}
                               okText={'Invia'} />
              </Form.Item>
              <Button onClick={() => form.validateFields().then(() => setScheduleModalOpen(true))} size={'large'}
                      icon={<CalendarOutlined />} className={'w-full mb-10'}>
                Programma l'invio
              </Button>
            </Form>
          </div>
          <div style={{ width: '100%', maxWidth: 350 }} className={'previewPhone'}>
            <PhonePreviewFrame>
              <NotificationPreview notificationStatus={'pending'} widgets={withOneTimePromotion ? [{
                type: 'one_time_promo',
                options: { promo_id: '', description: oneTimePromoDescription }
              }] : undefined} scale={1.2}
                                   ctaValue={ctaValue} ctaType={ctaType} ctaLabel={ctaLabel}
                                   imageUrl={imagePreviewUrl}
                                   title={title}
                                   text={text} />
            </PhonePreviewFrame>
          </div>
        </>
        <NotificationScheduleModal open={scheduleModalOpen} onSubmit={schedule.mutate} loading={schedule.isPending}
                                   onCancel={() => setScheduleModalOpen(false)}
                                   defaultValue={scheduleTimeFromSearch ? dayjs(scheduleTimeFromSearch) : undefined} />
      </div>
    </>);
}
