import { Suspense } from 'react';
import Wrapper from '../../Wrapper';
import OneTimePromosUsage from '../OneTimePromosUsage';
import MostUsedOneTimePromos from '../MostUsedOneTimePromos';

const OneTimePromosTab = ({selectedSalesPointId}: {selectedSalesPointId: string}) => {
  return (
    <div className={'grid-cols-1 grid gap-y-[50px] gap-x-7 lg:grid-cols-2'}>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <OneTimePromosUsage selectedSalesPointId={selectedSalesPointId} />
      </Suspense>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <MostUsedOneTimePromos selectedSalesPointId={selectedSalesPointId} />
      </Suspense>
    </div>
  );
};
export default OneTimePromosTab;
