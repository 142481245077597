import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';

const steps = [
  {
    title: 'Sono arrivati i buoni sconto!',
    content: 'Ora puoi creare buoni sconto digitali e inviarli direttamente tramite notifica. Un modo in più per fidelizzare i tuoi clienti e incentivare le vendite.',
    imgUrl: 'https://nextisfree.io/_astro/discount-card.F1hxnVIu_Z2tMHOV.webp'
  },
  { title: 'Riscatto Veloce e Sicuro', content: 'Ogni buono sconto può essere utilizzato una sola volta. Il cliente lo riscatta inquadrando il QR code con l’app Business, assicurandoti che lo sconto venga applicato in modo sicuro e senza possibilità di duplicazione.', imgUrl: 'https://nextisfree.io/_astro/discount-card.F1hxnVIu_Z2tMHOV.webp' },
  { title: 'Una sezione dedicata per non passare inosservato', content: 'I tuoi buoni sconto saranno sempre in evidenza in un’apposita area dell’app, così i clienti potranno trovarli facilmente e approfittarne al momento giusto. Mai più promozioni dimenticate o sprecate.', imgUrl: 'https://nextisfree.io/_astro/buoni-sconto-section.Dx0dnWQ7_12S6wO.webp' },
  { title: 'Sconti validi solo quando vuoi tu', content: 'Decidi tu fino a quando un buono sconto può essere utilizzato. Imposta la data di scadenza e crea promozioni a tempo limitato per spingere i clienti ad approfittarne prima che sia troppo tardi.', imgUrl: 'https://nextisfree.io/_astro/schedule-buoni-sconto.DzofSZms_rlzo3.webp' }
];

const AnnouncementModal = () => {

  const validTo = dayjs('2025-02-28')
  const [open, setOpen] = useState(false);

  const announcementsModal = localStorage.getItem('announcementModal');

  useEffect(() => {
    if(dayjs().isBefore(validTo) && !announcementsModal) {
      localStorage.setItem('announcementModal', 'true');
      setOpen(true);
    }
  }, []);


  const [step, setStep] = useState(0);

  const navigate = useNavigate()

  function handleOnOk() {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      setOpen(false);
      navigate({to: '/notifications/new'});
    }
  }

  return (
    <Modal closeIcon={null} width={600} open={open} onCancel={() => {
      setOpen(false);
    }} okText={'Prossimo'} onOk={handleOnOk} footer={
      <div className={'flex justify-between items-center'}>
        <button className={'text-blue-600'} onClick={() => {
          setOpen(false);
        }}>Salta
        </button>
        <div className={'flex gap-2'}>
          {
            step > 0 && <Button onClick={() => setStep(step - 1)}>
              Indietro
            </Button>
          }
          <Button type={'primary'} onClick={handleOnOk}>
            {step === steps.length - 1 ? 'Provali ora' : 'Prossimo'}
          </Button>
        </div>
      </div>
    }>
      <img src={steps[step].imgUrl}/>
      <div className={'justify-between items-center mt-5'}>
        <h1 className={'text-2xl font-semibold'}>{steps[step].title}</h1>
        <p>{steps[step].content}</p>
      </div>
    </Modal>
  );
};
export default AnnouncementModal;
