import { z } from 'zod';

export const usernameSchema = z.string().regex(/^[a-zA-Z0-9_]{3,40}$/);

export const UserDataSchema = z.object({
  username: usernameSchema,
  display_name: z.string().optional(),
  birthdate: z.string().optional()
});

export type UserData = z.infer<typeof UserDataSchema>;
