import { Card, Statistic, Tooltip } from 'antd';
import CountUp from 'react-countup';
import { gray } from '@ant-design/colors';
import SkeletonInput from 'antd/es/skeleton/Input';
import { COLORS } from '../../../utils/colors';
import Wrapper from '../../Wrapper';
import { Suspense } from 'react';
import { StepsFeedbackBadgeProps } from '../../../microcomponents/StepsBadge';
import StepsFeedbackBadge from '../../../microcomponents/StepsBadge';

interface SimpleStatisticProps {
  title: string,
  value: number
  suffix?: string
  valueStyle?: any
  tooltipText?: string
  noData?: boolean
  disableFormatter?: boolean
  stepsFeedbackBadge?: StepsFeedbackBadgeProps
}


const SimpleStatistic = ({
                           title,
                           value,
                           suffix = '',
                           tooltipText = '',
                           noData,
                           stepsFeedbackBadge
                         }: SimpleStatisticProps) => {

  /*    const formatter: any = (value: number) => <CountUp duration={0} preserveValue={true} decimal={','} end={value}/>;*/

  return (
    <Tooltip title={tooltipText} placement={'bottomLeft'} className={`${tooltipText && 'cursor-help'}`}>
      {noData ?
        <div>
          <p className={'text-slate-500'}>{title}</p>
          <p style={{fontWeight: 500 }} className={'text-slate-400 text-[14px] leading-[18px]'}>-</p>
        </div> :
        <div>
          <p className={'text-slate-500'}>{title}</p>
          <div className={'flex gap-1 items-center'}>
            {
              stepsFeedbackBadge &&
              <StepsFeedbackBadge {...stepsFeedbackBadge} />
            }
            <Statistic
              precision={0}
              value={value}
              suffix={suffix}
              valueStyle={{ fontSize: 20, fontWeight: 500, lineHeight: 1 }}
            />
          </div>
        </div>
      }
    </Tooltip>
  );
};
export default SimpleStatistic;
