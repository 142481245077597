import * as React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import useShopAttrs from '../../../utils/hooks/useShopAttrs';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { invoicesQueryOptions, subscriptionQueryOptions } from '../../../utils/queryOptions';

import { Button, Form } from 'antd';

import { green, red } from '@ant-design/colors';

import { useForm } from 'antd/es/form/Form';
import Wrapper from '../../../components/Wrapper';
import SalesPointsForm from '../../../components/billings/SalesPointsForm';
import SubscriptionOverview from '../../../components/billings/SubscriptionOverview';
import PaymentMethodOverview from '../../../components/billings/PaymentMethodOverview';
import PageLoader from '../../../microcomponents/PageLoader';
import SubscriptionInput from '../../../components/SubscriptionInput';
import { apiV1 } from '../../../utils/apiClient';


export const Route = createFileRoute('/_authenticated/billings')({
  component: RouteComponent,
  pendingComponent: PageLoader
});

function RouteComponent() {

  const [form] = useForm();

  const shopAttrs = useShopAttrs();
  const subscription = useSuspenseQuery(subscriptionQueryOptions());
  const invoices = useSuspenseQuery(invoicesQueryOptions());

  async function subscribe(values: any) {
    await apiV1.post('/shop/subscription', {
      subscriptionType: values.subscription.subscriptionType,
      promoCode: values.subscription.promoCode
    });
  }

  const createNewSubscription = useMutation({
    mutationKey: ['subscription', 'create'],
    mutationFn: subscribe,
    onSuccess: () => {
      window.location.reload();
    }
  });

  return (
    <div className={'container max-w-[700px] pb-10'}>
      <h1
        className={'font-bold text-xl'}
        style={{ margin: '40px 15px' }}>{shopAttrs.data.isSubscriptionValid ? 'Abbonamento' : 'Non hai un abbonamento attivo'}</h1>
      {shopAttrs.data.isSubscriptionValid ? (
        <SubscriptionOverview {...subscription.data} />
      ) : (
        <>
          <Form form={form} onFinish={createNewSubscription.mutate}
                initialValues={{ subscription: { promoCode: '', subscriptionType: 'yearly' } }}
          >
            <Form.Item name={'subscription'}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              <SubscriptionInput withoutFreeTrial />
            </Form.Item>
          </Form>
          <Button size={'large'} loading={createNewSubscription.isPending} onClick={() => form.submit()} type={'primary'}
                  style={{ borderRadius: 30, width: '100%', marginBottom: 30 }}>Abbonati</Button>
        </>

      )}
      <PaymentMethodOverview subscription={subscription.data} />
      <SalesPointsForm />
      <Wrapper mt={10}>
        <h3>Fatture</h3>
        {invoices.data.map((invoice) => (
          <div
            onClick={() => {
              invoice?.hosted_invoice_url &&
              window.open(invoice.hosted_invoice_url);
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'white',
              padding: 10,
              borderRadius: 10,
              marginBottom: 10,
              cursor: 'pointer',
              alignItems: 'center'
            }}
            key={invoice.id}
          >
            <b>{invoice.date}</b>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              {invoice.status === 'paid' ? (
                <p
                  style={{
                    padding: '3px 10px',
                    backgroundColor: green[1],
                    borderRadius: 5,
                    color: green[6],
                    fontSize: 13
                  }}
                >
                  Pagata
                </p>
              ) : invoice.status === 'open' ? (
                <p
                  style={{
                    padding: '3px 10px',
                    backgroundColor: red[1],
                    borderRadius: 5,
                    color: red[6],
                    fontSize: 13
                  }}
                >
                  Da pagare
                </p>
              ) : (
                <p
                  style={{
                    padding: '3px 10px',
                    backgroundColor: red[1],
                    borderRadius: 5,
                    color: red[6],
                    fontSize: 13
                  }}
                >
                  Annullata
                </p>
              )}
              <p>{invoice.amount}€</p>
            </div>
          </div>
        ))}
      </Wrapper>
    </div>
  );
}
