import { createFileRoute, Outlet, useLocation, useNavigate } from '@tanstack/react-router';
import { Spin, Tabs, TabsProps } from 'antd';
import * as React from 'react';
import Loader from '../../../../components/Loader';

export const Route = createFileRoute('/_authenticated/settings')({
  component: RouteComponent,
});

function RouteComponent() {

  const navigate = useNavigate()
  const location = useLocation();

  const tabs: TabsProps['items'] = [
    {
      key: 'general',
      label: 'Generale',
      children: <div className={'mb-20'}><Outlet/></div>,
      /* icon: <HugeiconsIcon icon={StarFreeIcons} className={'inline-block'} size={12} strokeWidth={1.5}/>*/
    },
    {
      key: 'staff',
      label: 'Staff',
      children: <div className={'mb-20'}><Outlet/></div>,
      /*    icon: <HugeiconsIcon icon={Megaphone03FreeIcons} className={'inline-block'} size={12} strokeWidth={1.5}/>*/
    },
  ]


  return (
    <div className={'max-w-xl m-auto mt-5'}>
      <Tabs activeKey={location.pathname === '/settings' ? 'general' : location.pathname.split('/').pop()} onChange={(selected)=> navigate({to: selected === 'general' ? '/settings' : '/settings/'+selected})} items={tabs} />
    </div>
  );
}
