import ContainerBlock from './ContainerBlock';
import HorizontalRankBars from './charts/HorizontalRankBars';
import { useSuspenseQuery } from '@tanstack/react-query';
import { readNotificationsQueryOptions, transactionsQueryOptions } from '../../utils/queryOptions';
import { endDate, startingDate } from '../../src/routes/_authenticated/analytics';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import NotificationPreview from '../NotificationPreview';
import NotificationPreviewModal from '../notifications/NotificationPreviewModal';
import { useNavigate } from '@tanstack/react-router';

interface MostReadNotificationsProps {
}

const MostUsedOneTimePromos = ({ selectedSalesPointId }: { selectedSalesPointId: string }) => {

  const [seeAllModalOpen, setSeeAllModalOpen] = useState(false);
  const navigate = useNavigate()

  const transactions = useSuspenseQuery(transactionsQueryOptions(endDate.value, startingDate.value, selectedSalesPointId));
  const oneTimePromos = transactions.data.filter((item: any) => !!item.one_time_promo_id);

  const groupedUsageById = oneTimePromos.reduce((acc, used) => {
    const existingUsed = acc.find(item => item.id === used.one_time_promo_id);
    if (existingUsed) {
      existingUsed.value++;
    } else {
      acc.push({ index: `${used.goal_name} (${used.one_time_promo_id})`, value: 1, id: used.one_time_promo_id || '', title: used.goal_name || '' });
    }
    return acc;
  }, [] as Array<{ index: string, value: number, id: string, title: string }>);

  // sort by value ad return max 10
  groupedUsageById.sort((a, b) => a.value - b.value);

  return (
    <>
      <ContainerBlock noData={groupedUsageById.length < 1} customPlaceholderElement={<Button className={'mt-2'} onClick={()=>navigate({to: '/notifications/new'})}>Crea un buono sconto</Button>} title={'Buoni sconto più usati'}>
        <div />
        <HorizontalRankBars
          data={groupedUsageById.slice(-10)}
        />
        {groupedUsageById.length > 10 &&  <p onClick={()=>setSeeAllModalOpen(true)} style={{ marginLeft: 90 }} className={'underline cursor-pointer text-sm text-slate-500'}>Vedi tutte...</p>}
      </ContainerBlock>
      <Modal width={'100%'} style={{maxWidth: 700}} open={seeAllModalOpen} onCancel={()=>setSeeAllModalOpen(false)} footer={null}>
          <div style={{width: '100%', height: groupedUsageById.length * 30}}>
            <p className={'font-semibold'}>Notifiche più lette</p>
            <HorizontalRankBars
              data={groupedUsageById}
            />
          </div>
      </Modal>
    </>
  );
};
export default MostUsedOneTimePromos;
