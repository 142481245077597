import { z } from 'zod';
import { CallToActionSchema } from './callToAction';
import { notificationWidgetSchema, notificationWidgetTypes, userNotificationWidgetSchema } from './notificationWidgets';
import { invalidateDataGloballyActionSchema } from './queries';

export const NotificationStatusSchema = z.enum(['pending', 'sent', 'archived']);

export type NotificationStatus = z.infer<typeof NotificationStatusSchema>

export const notificationsStatusLabels = {
  pending: 'Programmata',
  sent: 'Inviata',
  archived: 'Archiviata'
};

export const notificationsStatusColors: {
  [status in NotificationStatus]: {
    light: string,
    bg: string,
    text: string
  }
} = {
  pending: { light: 'bg-blue-100', text: 'text-blue-700', bg: 'bg-blue-200' },
  sent: { light: 'bg-emerald-100', text: 'text-emerald-700', bg: 'bg-emerald-200' },
  archived: { light: 'bg-slate-100', text: 'text-slate-600', bg: 'bg-slate-200' }
};

export const NotificationSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  text: z.string().optional(),
  image: z.boolean(),
  created_at: z.string().date(),
  status: NotificationStatusSchema,
  sent_at: z.string().date().nullable(),
  schedule_time: z.string().date().nullable(),
  call_to_actions: CallToActionSchema.array().nullish(),
  archived_at: z.string().date().nullable(),
  widgets: notificationWidgetTypes.array().nullish()
});

export type Notification = z.infer<typeof NotificationSchema>

export const NewNotificationSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  text: z.string(),
  image: z.boolean(),
  business_name: z.string(),
  brand_color: z.string().optional(), //to allow backward compatibility
  shop_latest_update_at: z.string(),
  call_to_actions: z.array(CallToActionSchema).nullish(),
  widgets: notificationWidgetSchema.array().nullish()
});

export type NewNotification = z.infer<typeof NewNotificationSchema>

export const ScheduleNotificationSchema = NewNotificationSchema.extend({
  schedule_time: z.string().datetime()
});

export type ScheduleNotification = z.infer<typeof ScheduleNotificationSchema>

export const PushNotificationTypeSchema = z.enum(['shop_news', 'trigger_data_update', 'invalidate_data_globally']);

export type PushNotificationType = z.infer<typeof PushNotificationTypeSchema>

export const pushNotificationsTypePrefixes: { [key in PushNotificationType]: string } = {
  shop_news: 'v2%',
  trigger_data_update: 'trigger_data_update%',
  invalidate_data_globally: 'invalidate_data_globally%'
};

export const UserShopNewsSchema = z.object({
  id: z.string().uuid(),
  shop_id: z.string().uuid().optional(),
  image: z.boolean().optional(),
  business_name: z.string().optional(),
  brand_color: z.string().optional(),
  shop_latest_update_at: z.string().date().optional(),
  type: PushNotificationTypeSchema.extract(['shop_news']),
  has_widgets: z.boolean().optional(),
  widgets: notificationWidgetTypes.array().optional(),
  sent_at: z.string().date().optional(),
  call_to_actions: CallToActionSchema.array().nullish().optional(),
  title: z.string().optional(),
  text: z.string().optional(),
  read: z.boolean().optional(),
  saved: z.boolean().optional()
});

export type UserShopNews = z.infer<typeof UserShopNewsSchema>


export const PushNotificationDataSchema = UserShopNewsSchema.or(z.object({
  //this is valid only until 2.0.0 after is replaced by 'invalidate_data_globally'
  type: PushNotificationTypeSchema.extract(['trigger_data_update']),
  data_key: z.enum(['fetchPrivacySettings', 'oneTimePromoRedeemed'])
})).or(z.object({
  type: z.literal('invalidate_data_globally'),
  queryKeys: z.array(z.string()),
  action: invalidateDataGloballyActionSchema
}));

export type PushNotificationData = z.infer<typeof PushNotificationDataSchema>

export const UserNotificationsResultsSchema = z.object({
  notifications: z.array(UserShopNewsSchema),
  hasMore: z.boolean(),
  nextPage: z.number().nullable()
});

export type UserNotificationsResults = z.infer<typeof UserNotificationsResultsSchema>

export const userNotificationWidgetsSchema = userNotificationWidgetSchema.array().nullish();
export type UserNotificationWidgets = z.infer<typeof userNotificationWidgetsSchema>


export const NotificationsFilter = z.enum(['one-time-promo', 'saved']).optional();

export type NotificationsFilter = z.infer<typeof NotificationsFilter>

export const notificationDetailsSchema = z.object({
  notification_id: z.string().uuid(),
  read_count: z.string(),
  saved_count: z.string(),
  cta_clicks_count: z.string(),
  one_time_promos: z.object({
    promo_id: z.string().uuid(),
    description: z.string(),
    valid_to: z.string().date().nullish(),
    valid_from: z.string().date().nullish(),
    redeemed_count: z.string()
  }).array()
});

export type NotificationDetails = z.infer<typeof notificationDetailsSchema>
