import { useState } from 'react';
import {
  BookOutlined,
  CreditCardOutlined,
  MobileOutlined,
  NotificationOutlined, PrinterOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  StockOutlined, SwapOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Flex, Layout, Menu, MenuProps, theme } from 'antd';
import { useLocation, useNavigate } from '@tanstack/react-router';

import SubscriptionCheckBanner from '../components/SubscriptionCheckBanner';
import TopInfoBanner from '../components/TopInfoBanner';
import { COLORS } from '../utils/colors';
import { MenuItemType } from 'antd/lib/menu/interface';
import { IconDots } from '@tabler/icons-react';
import useShopAttrs from '../utils/hooks/useShopAttrs';
import AnnouncementModal from '../components/AnnouncementsModal';
import { HugeiconsIcon } from '@hugeicons/react';
import {
  ArrowDataTransferHorizontalFreeIcons,
  Chart03Icon, CustomerService01FreeIcons, HelpCircleFreeIcons,
  Megaphone01FreeIcons, Megaphone03FreeIcons, PrinterIcon, QuestionFreeIcons, ScratchCardFreeIcons,
  SearchIcon, Setting06FreeIcons, Settings02FreeIcons, SmartPhone01FreeIcons
} from '@hugeicons/core-free-icons';

const { Content, Sider } = Layout;

const AuthLayout = ({ children }: any) => {

  const [collapsed, setCollapsed] = useState(false);

  const shopAttrs = useShopAttrs();

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();


  const menuItems = [{
    icon: Chart03Icon, path: '/analytics', label: 'Analitiche', onClick: () => {
      navigate({ to: '/analytics' });
    }
  }, {
    icon: ArrowDataTransferHorizontalFreeIcons, path: '/transactions', label: 'Transazioni', onClick: () => {
      navigate({ to: '/transactions' });
    }
  }, {
    icon: Megaphone03FreeIcons,
    path: '/notifications',
    label: 'Notifiche',
    onClick: () => {
      navigate({ to: '/notifications' });
    }
  }, {
    icon: SmartPhone01FreeIcons,
    path: '/customize',
    label: 'Personalizza',
    onClick: () => {
      navigate({ to: '/customize' });
    }

  }, {
    icon: PrinterIcon, path: '/resources', label: 'Risorse', onClick: () => {
      navigate({ to: '/resources' });
    }
  }, {
    icon: ScratchCardFreeIcons,
    path: '/billings',
    label: 'Abbonamento',
    onClick: () => {
      navigate({ to: '/billings' });
    }
  },
    {
      icon: Settings02FreeIcons,
      path: '/settings',
      label: 'Impostazioni',

      onClick: () => {
        navigate({ to: '/settings' });
      }
    }
  ];

  const mobileMenuItems = [{
    icon: <StockOutlined />,
    path: '/analytics',
    label: 'Analitiche'
  },
    {
      icon: <SwapOutlined />,
      path: '/transactions',
      label: 'Transazioni'
    },
    {
      icon: <NotificationOutlined />,
      path: '/notifications',
      label: 'Notifiche'
    },
    {
      icon: <MobileOutlined />,
      path: '/customize',
      label: 'Personalizza'
    }

  ];


  const items: MenuProps['items'] = [
    /*  {
          label: <Flex style={{fontSize: 16}} onClick={() => navigate({to: '/resources'})} gap={6}> <BookOutlined/><p>Guida</p></Flex>,
          key: '0',
      },*/
    {
      label: <Flex style={{ fontSize: 16 }} onClick={() => navigate({ to: '/billings' })} gap={6}><CreditCardOutlined />
        <p>Abbonamento</p>
      </Flex>,
      key: '1'
    },
    {
      label: <Flex style={{ fontSize: 16 }} onClick={() => navigate({ to: '/settings' })} gap={6}> <SettingOutlined />
        <p>Impostazioni</p></Flex>,
      key: '3'
    }
  ];


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AnnouncementModal />
      <div className={'w-full h-[40px] md:hidden'}>
        <nav style={{
          position: 'fixed',
          backgroundColor: 'white',
          top: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          borderBottom: `1px solid ${COLORS.lightGray}`,
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 900,
          height: 45,
          padding: '8px 15px',
          fontSize: 20
        }}>
          <Flex align={'center'}>
            <img width={23} src={'/favicon.ico'} />
            <b style={{ marginLeft: 3, fontSize: 15, marginTop: 3 }}>Business</b>
          </Flex>
          <Dropdown menu={{ items }} placement="bottomRight">
            <Button style={{ width: 35, height: 35, padding: 0 }}><IconDots size={20} /></Button>
          </Dropdown>
        </nav>
      </div>
      <Layout>
        <Sider
          className={'max-md:hidden'}
          collapsedWidth={60}
          theme={'dark'}
          breakpoint={'xl'}
          onCollapse={(state) => {
            setCollapsed(state);
          }}
          width={200}
          style={{
            minHeight: 10,
            height: '100vh',
            position: 'relative'
          }}
        >
          <div style={{
            position: 'fixed',
            height: '100vh',
            minHeight: 500,
            width: collapsed ? 60 : 200,
            backgroundColor: 'white'
          }}
               className={'p-2 border-r border-slate-200'}
          >
            <div className={'flex items-center gap-2 p-2 cursor-pointer mb-5'} onClick={() => navigate({ to: '/settings' })}>
              <img alt={'logo'} width={'35px'} src={'/menu-logo.svg'} />
              {!collapsed &&
                <div className={'text-xs'}>
                  <p className={'font-medium line-clamp-1 text-sm mb-0 leading-none'}>{shopAttrs.data.business_name}</p>
                  <p
                    className={'text-slate-500 leading-none text-xs'}>{shopAttrs.data.isVisible ? 'Pubblico' : 'Nascosto'}</p>
                </div>
              }
            </div>
            {menuItems.map((item) => (
              <NavButton path={item.path} collapsed={collapsed} selected={location.pathname.includes(item.path)}
                         label={item.label} icon={item.icon}
                         key={item.path} />
            ))}
            <div className={'absolute bottom-4'}>
              <NavButton label={'Aiuto'} selected={false} collapsed={collapsed} icon={CustomerService01FreeIcons} path={'/aiuto'} onClick={()=> window.open('https://nextisfree.io/contatta-vendite', '_blank')?.focus()} />
              <NavButton label={'Help Center'} selected={false} collapsed={collapsed} icon={HelpCircleFreeIcons} path={'/help'} onClick={()=> window.open('https://help.nextisfree.io', '_blank')?.focus()}/>
            </div>
          </div>
        </Sider>
        <Layout
          style={{ backgroundColor: 'white' }}
        >
          {!shopAttrs.data.completed &&
            <TopInfoBanner onClick={() => navigate({ to: '/customize' })}
                           text={', completa il profilo per essere visibile nell\'app'}
                           boldText={'Il tuo negozio è nascosto'} buttonText={'Completa profilo'} />
          }
          {/*    <SubscriptionCheckBanner/>*/}
          <Content>
            {children}
          </Content>
        </Layout>
      </Layout>

      <div className={'h-[50px] w-full md:hidden'}>
        <div style={{ borderTopColor: COLORS.lightGray }} className={'mobile-bottom-nav'}>
          {mobileMenuItems.map((item) => (
            <BottomNavBtn key={item.label} {...item} />
          ))}
        </div>
      </div>

    </Layout>
  );
};
export default AuthLayout;

function BottomNavBtn({ icon, path, label }: any) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate({ to: path })} style={{
      backgroundColor: pathname == path ? COLORS.primary : 'white',
      borderRadius: 20,
      height: 38,
      padding: '0 15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: pathname == path ? 'black' : COLORS.gray
    }}>
      {icon}
      {pathname == path &&
        <p style={{ fontSize: 12, marginLeft: 4 }}>{label}</p>
      }
    </div>
  );
}

function NavButton({ label, selected, collapsed, icon, path, onClick }: {
  label: string,
  selected: boolean,
  collapsed: boolean,
  icon: any,
  path: string,
  onClick?: () => void
}) {

  const navigate = useNavigate();

  const activeClass = selected ? 'bg-primary text-black' : 'hover:bg-slate-100 text-slate-700';
  const collapsedClass = collapsed ? 'justify-center' : 'justify-start';

  return (
    <div onClick={() => onClick ? onClick() : navigate({ to: path })}
         className={`w-full h-[32px] rounded-md flex items-center gap-1 px-2 cursor-pointer font ${activeClass} mb-1 ${collapsedClass}`}
    >
      <HugeiconsIcon
        icon={icon}
        size={18}
        color="currentColor"
        strokeWidth={0.5}
      />
      {collapsed ? null : <p style={{ fontSize: 14, marginLeft: 4 }}>{label}</p>}
    </div>
  );
}
