import { Button, Empty, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NotificationCard from '../NotificationCard';
import { Link, useNavigate } from '@tanstack/react-router';
import * as React from 'react';
import useShopAttrs from '../../utils/hooks/useShopAttrs';
import { Notification } from '@next-is-free-monorepo/next-is-free-types';
import { IconInbox } from '@tabler/icons-react';

interface NotificationsListProps {
}

const NotificationsList = ({notifications, setSelectedNotificationId, selectedNotificationId}: {notifications: Notification[], setSelectedNotificationId: (id: string)=>void, selectedNotificationId: string | undefined}) => {
  const shopAttrs = useShopAttrs();
  const navigate = useNavigate()

  return (
    <div className={'border-r-[1px] flex flex-col w-[100vw] md:max-h-[calc(100vh-50px)] md:max-w-[25vw] md:h-[100vh]'}>
    <div className={'flex items-center justify-between h-[50px] p-3 border-b-[1px] bg-white w-full border-b-slate-200 fixed top-[95px] z-[900] md:static'}>
      <p className={'text-lg font-semibold'}>
        Notifiche
      </p>
      <div className={''}>
        <Popover
          title={
            !shopAttrs.data.isVisible &&
            'Il tuo negozio deve essere pubblico per poter inviare notifiche'
          }
        >
          <Button
            disabled={!shopAttrs.data.isVisible}
            type={'primary'}
            className={'w-full'}
            onClick={() => {
              navigate({ to: '/notifications/new' });
            }}
          >
            <PlusOutlined />
            Nuova
          </Button>
        </Popover>
      </div>
    </div>
      <div className={'pb-[50px] md:overflow-y-scroll scrollbar-thumb-slate-200 scrollbar-thin scrollbar-track-white pt-[95px] md:pb-0 md:pt-0 md:flex-1'}>
        {notifications.map((item, index) => (
          <NotificationCard onClick={() => setSelectedNotificationId(item.id)} key={item.id}
                            selected={selectedNotificationId ? selectedNotificationId === item.id : false} item={item} />
        ))}
        {!notifications[0] && (
          <div className={'w-[100vw] min-h-[300px] md:w-[350px] h-full flex flex-col justify-center items-center text-center text-slate-300'}>
            <IconInbox className={'mb-2'} size={30} />
            <p className={'leading-[1.2]'}>Ancora nessuna<br />notifica</p>
          </div>
        )}
      </div>
  </div>);
};
export default NotificationsList;
