import { ReactNode } from 'react';
import { grey } from '@ant-design/colors';
import CountUp from 'react-countup';
import { COLORS } from '../../utils/colors';
import { Select } from 'antd';
import { HugeiconsIcon } from '@hugeicons/react';
import { CloudFreeIcons } from '@hugeicons/core-free-icons';

interface ContainerBlockProps {
  title: string;
  children: ReactNode;
  total?: number;
  customRightElement?: ReactNode;
  noData?: boolean;
  customPlaceholderElement?: ReactNode;
}

const ContainerBlock = ({ title, children, total, customRightElement, noData, customPlaceholderElement }: ContainerBlockProps) => {

  const formatter: any = (value: number) => <CountUp duration={0} preserveValue={true} decimal={','} end={value} />;

  return (
    <div
      style={{
        width: '100%',
        aspectRatio: 1.7,
        minHeight: 250,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 30,
        backgroundColor: 'white'
      }}
    >
      <div className={'w-full flex justify-between'}>
        <div>
          <h3 style={{ width: '100%' }} className={'font-medium'}>{title}</h3>
          {total ? <p className={'w-full text-slate-500 text-sm'}>Totale: {total.toFixed(total % 1 ? 2 : 0)}</p>: <p/>}
        </div>
        {customRightElement}
      </div>
      <div style={{ width: '100%', height: 'calc(100% - 40px)' }}>
        {noData ?
          <div
            className={' w-full h-full rounded-md border-slate-200 border bg-slate-50 flex justify-center items-center flex-col text-slate-400'}>
            <HugeiconsIcon icon={CloudFreeIcons} />
            <p>Ancora nessun dato</p>
            {customPlaceholderElement}
          </div>
          :
          children
        }</div>
    </div>
  );
};
export default ContainerBlock;
