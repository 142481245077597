import ContainerBlock from './ContainerBlock';
import SingleLineChartTimeFormat from './charts/SingleLineChartTimeFormat';

import { groupDatesBy, sumEuros, sumPoints, sumYValues } from '../../utils/analytics';
import { endDate, startingDate, timeSpan } from '../../src/routes/_authenticated/analytics';
import { useSuspenseQuery } from '@tanstack/react-query';
import { transactionsQueryOptions } from '../../utils/queryOptions';
import { Select } from 'antd';
import { useState } from 'react';

const OneTimePromosUsage = ({ selectedSalesPointId }: { selectedSalesPointId: string }) => {

  const transactions = useSuspenseQuery(transactionsQueryOptions(endDate.value, startingDate.value, selectedSalesPointId));
  const groupedByDate = groupDatesBy(transactions.data, timeSpan.value);

  function countOneTimePromosOccurrences(inputArray: any[]) {
    return inputArray.map((item)=>{
      const somma =  item.data.reduce((sum: number, item: any) => {
        if (item.type === 2) {
          console.log(sum)
          return sum + 1;
        }
        return sum;
      }, 0);

      return {x: item.key, y: somma}
    })
  }

  const oneTimePromosUsage = countOneTimePromosOccurrences(groupedByDate);

  return (
    <ContainerBlock
                    total={sumYValues(oneTimePromosUsage)}
                    title={'Buoni sconto usati'}>
      <SingleLineChartTimeFormat data={oneTimePromosUsage} />
    </ContainerBlock>
  );
};
export default OneTimePromosUsage;
