// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ComputedDatum, ResponsiveBar } from '@nivo/bar';
import dayjs from 'dayjs';
import { timeSpan } from '../../../src/routes/_authenticated/analytics';
import { COLORS } from '../../../utils/colors';
import { BarItem } from './CustomBarItem';

function xDateFormat(fullDate: string) {
  switch (timeSpan.value) {
    case 'year':
      return dayjs(fullDate).format('MMM YY');
    case 'month':
      return dayjs(fullDate).format('D');
    case 'week':
      return dayjs(fullDate).format('ddd');
  }
}


const BarsChart = ({ data, onBarClick = ()=>{} }: {
  data: { id: string, title: string, index: string, value: number }[],
  onBarClick?: (e: ComputedDatum<{ id: string, title: string, index: string }>) => void
}) => (
  <ResponsiveBar
    data={data}
    keys={['value']}
    indexBy="index"
    layout="horizontal"
    padding={0.4}
    margin={{ top: 10, left: 90 }}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    borderRadius={5}
    labelSkipWidth={12}
    labelSkipHeight={12}
    axisLeft={{
      format: (v) => {
        return v.length > 10 ? (
          v.substring(0, 10) + '...'
        ) : (
          v
        );
      },
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: -40
    }}
    labelTextColor={'black'}
    legends={[]}
    role="application"
    ariaLabel="Nivo horizontal bar chart example"
    colors={COLORS.primary}
    enableGridY={false}
    barComponent={BarItem}
    onClick={(value) => onBarClick(value)}
    tooltipLabel={(d) => d.data.title as string}
  />

);

export default BarsChart;
