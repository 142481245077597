import { Api } from '@next-is-free-monorepo/next-is-free-shared-utils';
import { apiUrl } from './urls';
import { supabase } from './supabase';
import { message } from 'antd';
import { errorsTranslations } from '@next-is-free-monorepo/i18n';


export const apiV1 = new Api(1, apiUrl, (error) => {
  message.error(errorsTranslations.it[error]);
}, supabase);
