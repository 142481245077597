import { useRef, useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { Button, Modal } from 'antd';
import GoalBarPreview from '../GoalBarPreview';
import PhonePreviewFrame from '../PhonePreviewFrame';
import useShopAttrs from '../../utils/hooks/useShopAttrs';
import { bucketUrl } from '../../utils/urls';
import { supabase } from '../../utils/supabase';
import { useQuery } from '@tanstack/react-query';
import { GoalType } from '@next-is-free-monorepo/next-is-free-types';
import { toJpeg } from "html-to-image";
import QrCodeWithLogo from 'qrcode-with-logos';
import logo from '../../src/assets/icon.png';
import { DownloadOutlined } from '@ant-design/icons';

const Flyer = ({qrCode}: {qrCode: string}) => {
  const flyerRef = useRef(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [imageSrc, setImageSrc] = useState('');
  const [open, setOpen] = useState(false);
  const [dowloading, setDowloading] = useState(false);

  const shopAttrs = useShopAttrs();
  const goals = useQuery({
    queryKey: ['goals'], queryFn: async (): Promise<GoalType[]> => {
      const { data, error } = await supabase
        .from('shops')
        .select('goals')
        .eq('id', shopAttrs.data.id);
      return data?.[0]?.goals || [];
    }
  });

  // Adjust scale to fit screen
  useEffect(() => {
    const maxHeight = (window.innerHeight / 3.7795) / 1.5; // 80% of screen height
    const originalHeight = 297; // A4 height in mm
    setScaleFactor(originalHeight > maxHeight ? maxHeight / originalHeight : 1);

    async function generateQrCode() {
      const code = new QrCodeWithLogo({
        content: `https://nextisfree.io/shop/${qrCode}`,
        width: 700,
        download: false,
        logo: {
          src: logo
        }
      });

      const image = await code.getImage();
      setImageSrc(image.src);
    }

    generateQrCode();
  }, []);


  const handleDownloads = async () => {
    if (!flyerRef.current) return;
    try {
      setDowloading(true)


      //è necessario chiamare toJpeg più volte per evitare che il background sia trasparente su SAFARI
      await toJpeg(flyerRef.current, {
        quality: 1.0, // High quality
        backgroundColor: "white", // Ensures transparent areas are white
      });
      await toJpeg(flyerRef.current, {
        quality: 1.0, // High quality
        backgroundColor: "white", // Ensures transparent areas are white
      });
      await toJpeg(flyerRef.current, {
        quality: 1.0, // High quality
        backgroundColor: "white", // Ensures transparent areas are white
      });
      await toJpeg(flyerRef.current, {
        quality: 1.0, // High quality
        backgroundColor: "white", // Ensures transparent areas are white
      });
      const dataUrl = await toJpeg(flyerRef.current, {
        quality: 1.0, // High quality
        backgroundColor: "white", // Ensures transparent areas are white
        cacheBust: true
      });

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "flyer.jpg";
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error generating flyer:", error);
    }finally {
      setDowloading(false)
    }
  };

  if(!open) return <Button onClick={()=>setOpen(true)} icon={<DownloadOutlined/>}>Scarica</Button>

  if(goals.isLoading) return <div>Loading...</div>


  return (
    <Modal onCancel={()=>setOpen(false)} footer={null} open={open} style={{paddingTop: 20}}>
    <div style={{zoom: scaleFactor, paddingTop: 40, margin: 'auto', display: 'flex', justifyContent: 'center'}}>
      <div
        ref={flyerRef}
        style={{
          width: `${210 * 1}mm`, // Keep A4 aspect ratio
          height: `${297 * 1}mm`,
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '24px', // Scale font size accordingly
          position: 'relative',
          fontFeatureSettings: '"liga" 0',

        }}
      >
        <img alt={''} width={'100%'} src={'./resources/goals-flyer-bg.jpg'} />
        <div style={{
          position: 'absolute',
          bottom: `30mm`,
          left: 0,
          width: '100%',
          height: `175mm`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <div style={{ marginLeft: '14mm', display: 'flex', flexDirection: 'column', gap: '2mm' }}>
            {goals?.data?.map((goal: GoalType, index: number) => (
              <div key={index} style={{ backgroundColor: 'white', height: '18mm', width: '90mm', borderRadius: 10, display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src={'./star.svg'} alt={''} style={{marginLeft: 15}} />
                <div className={'ml-[5px]'}>
                  <p className={'text-sm text-slate-500 text-left leading-[1] mb-[0.5]'}>{goal.number} punti</p>
                  <p className={'font-medium text-lg line-clamp-2 text-left leading-[1]'}>{goal.title}</p>
                </div>
              </div>
            ))}
            <div style={{ backgroundColor: 'white', height: '90mm', width: '90mm', borderRadius: 10 }}>
              <img src={imageSrc} alt={''} style={{width: '70mm', marginLeft: '10mm', marginTop: '10mm'}}/>
            </div>
          </div>
          <div style={{ zoom: 0.85, marginRight: '14mm' }}>
            <PhonePreviewFrame>
              <GoalBarPreview brandColor={shopAttrs.data.brand_color} goals={goals.data || []}
                              imagePreviewUrl={bucketUrl + '/' + shopAttrs.data.id + '/logo.png?' + shopAttrs.data.latest_update_at}
                              businessName={shopAttrs.data.business_name}
                              businessCategory={''} highestNumber={goals.data?.[goals.data.length - 1].number || 0} initialPoints={goals.data?.[goals.data.length - 1]?.number || 0} />
            </PhonePreviewFrame>
          </div>
        </div>
      </div>
    </div>
      <div style={{textAlign: 'center'}}>
        <Button loading={dowloading} icon={<DownloadOutlined/>} type={'primary'} onClick={handleDownloads} className={'mt-4'}>Scarica</Button>
      </div>
    </Modal>
  );
};

export default Flyer;
