import * as React from 'react';
import { createFileRoute, Outlet, SearchSchemaInput } from '@tanstack/react-router';
import PageLoader from '../../../../microcomponents/PageLoader';
import { useEffect, useState } from 'react';
import { Alert, Button, Empty, Popover, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { notificationsQueryOptions } from '../../../../utils/queryOptions';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import useShopAttrs from '../../../../utils/hooks/useShopAttrs';
import NotificationPreview from '../../../../components/NotificationPreview';
import { bucketUrl } from '../../../../utils/urls';
import NotificationsPageNavBar from '../../../../components/notifications/NotificationPageNavBar';
import NotificationDetails from '../../../../components/notifications/NotificationDetails';
import NotificationOptionBtn from '../../../../components/notifications/NotificationOptionsBtn';
import { IconInbox, IconMessage, IconNotification, IconX } from '@tabler/icons-react';
import NotificationsList from '../../../../components/notifications/NotificationsList';
import NotificationsCalendar from '../../../../components/notifications/NotificationsCalendar';
import { apiV1 } from '../../../../utils/apiClient';
import { NotificationWidget } from '@next-is-free-monorepo/next-is-free-types';

export const Route = createFileRoute('/_authenticated/notifications/')({
  component: RouteComponent,
  pendingComponent: PageLoader,
  validateSearch: (
    input: {
      view?: 'list' | 'calendar'
    } & SearchSchemaInput
  ) => {
    return {
      view: input.view || 'list'
    };
  }
});

function RouteComponent() {
  const shopsAttrs = useShopAttrs();
  const { view } = Route.useSearch();
  const [selectedNotificationId, setSelectedNotificationId] = useState<string | undefined>(undefined);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const notifications = useSuspenseQuery(notificationsQueryOptions());
  const selectedNotification = selectedNotificationId ? notifications?.data?.find((item) => item.id === selectedNotificationId) : undefined;

  const notificationWidgets = useQuery({
    queryKey: ['notificationWidgets', selectedNotificationId],
    queryFn: async () => apiV1.get<NotificationWidget[]>(`/shop/notifications/${selectedNotificationId}/widgets?widget_types=${selectedNotification?.widgets?.join('&widget_types=') || '[]'}`),
    enabled: !!selectedNotification?.widgets && selectedNotification.widgets?.length > 0
  });

  return (
    <div>
      <NotificationsPageNavBar selectedNotificationId={selectedNotificationId} selected={view} />
      <div className={'flex '} style={{ maxHeight: 'calc(100vh - 50px)' }}>
        <div className={`${selectedNotificationId && 'hidden md:block'}`}>
          {view === 'list' ?
            <NotificationsList notifications={notifications.data} setSelectedNotificationId={setSelectedNotificationId}
                               selectedNotificationId={selectedNotificationId} />
            :
            <NotificationsCalendar notifications={notifications.data}
                                   setSelectedNotificationId={setSelectedNotificationId}
                                   selectedNotificationId={selectedNotificationId} />
          }
        </div>
        <div className={`flex flex-col items-center flex-1 ${detailsOpen && 'md:hidden'} lg:flex`}>
          {selectedNotification ?
            <>
              <div
                className={'flex items-center justify-between h-[50px] p-3 w-full border-b-[1px] border-b-slate-200 fixed top-0 bg-white z-[998] md:static md:min-w-[300px]'}>
                <p className={'text-lg font-semibold'}>
                  Anteprima
                </p>
                <div className={'flex items-center gap-2'}>

                  <NotificationOptionBtn setSelectedNotificationId={setSelectedNotificationId}
                                         notificationId={selectedNotification.id}
                                         status={selectedNotification.status} />

                  {!detailsOpen
                    &&
                    <Button className={'rounded-3xl'} onClick={() => setDetailsOpen(true)}>Dettagli</Button>
                  }
                  <IconX className={`${view === 'list' && 'md:hidden'}`}
                         onClick={() => setSelectedNotificationId(undefined)} />
                </div>
              </div>
              <div
                className={'w-full flex-1 md:overflow-y-scroll scrollbar-track-white scrollbar-thin scrollbar-thumb-slate-200 pt-[5px] md:pt-0'}>
                <div style={{ width: '100%', maxWidth: 450, margin: 'auto' }} className={'h-[100vh]'}>
                  {selectedNotification.status === 'archived' &&
                    <Alert className={'mb-2'} banner={true} type={'warning'}
                           message={'Questa notifica è stata archiviata e non è visibile ai clienti.'} />
                  }
                  <NotificationPreview widgets={notificationWidgets.data} scale={1} ctaValue={selectedNotification.call_to_actions?.[0].value || ''}
                                       ctaType={selectedNotification.call_to_actions?.[0].type || 'no-cta'}
                                       ctaLabel={selectedNotification.call_to_actions?.[0].label || ''}
                                       imageUrl={selectedNotification.image ? bucketUrl + shopsAttrs.data.id + '/' + selectedNotification.id + '.jpeg' : ''}
                                       title={selectedNotification?.title}
                                       text={selectedNotification?.text} notificationStatus={selectedNotification?.status} />
                </div>
              </div>
            </> : view === 'list' &&
            <div
              className={'justify-center items-center flex-col h-full text-slate-300 text-center text-sm hidden md:flex'}>
              <IconInbox className={'mb-2'} size={30} />
              <p className={'leading-[1.2]'}>Seleziona <br />una notifica</p>
            </div>
          }
        </div>
        {selectedNotification &&
          <NotificationDetails open={detailsOpen} setOpen={setDetailsOpen} notification={selectedNotification} />
        }
      </div>
    </div>
  );
}
