import { Button, Calendar, CalendarProps, Select } from 'antd';
import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Link } from '@tanstack/react-router';
import { IconPlus } from '@tabler/icons-react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Notification, notificationsStatusColors, NotificationStatus } from '@next-is-free-monorepo/next-is-free-types';

interface NotificationsCalendarProps {
}

const NotificationsCalendar = ({ notifications, setSelectedNotificationId, selectedNotificationId }: {
  notifications: Notification[],
  setSelectedNotificationId: (id: string | undefined) => void,
  selectedNotificationId: string | undefined
}) => {

  function onNotificationClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) {
    e.preventDefault();
    e.stopPropagation();
    setSelectedNotificationId(id);
  }

  const dateCellRender = (value: Dayjs) => {
    let listData: Notification[] = [];

    for (const notification of notifications) {
      if (notification.status === 'sent' && value.isSame(notification.created_at, 'day')) {
        listData.push(notification);
      }
      if (notification.status === 'pending' && value.isSame(notification.schedule_time, 'day')) {
        listData.push(notification);
      }
      if (notification.status === 'archived' && value.isSame(notification.created_at, 'day')) {
        listData.push(notification);
      }
    }

    return (
      <div
        className={`h-[120px] flex flex-col border-t-[2px] md:mx-2 border-slate-200 group ${value.isBefore(dayjs().startOf('day')) && 'bg-slate-50'} ${value.isSame(dayjs(), 'day') && 'border-t-[5px] border-t-primary'}`}>
        <div className={'flex justify-between pt-1'}>
          <p className={`text-left text-slate-500`}>{value.date()}</p>
          {value.isAfter(dayjs().startOf('day')) &&
            <Link to={'/notifications/new'} search={{ scheduleTime: value.toISOString() }}
                  className={'justify-center items-center bg-blue-50 md:w-5 md:h-5 w-8 h-8 flex md:hidden rounded-3xl md:group-hover:flex hover:bg-primary hover:text-black'}><IconPlus
              size={12} /></Link>
          }
        </div>
        <div
          className={'flex-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-transparent'}>
          {listData.map((item, index) => (
            <div onClick={(e) => onNotificationClick(e, item.id)} key={index}
                 className={`text-left rounded-3xl px-2 flex items-center gap-1 hover:${notificationsStatusColors[item.status].light} ${selectedNotificationId === item.id && notificationsStatusColors[item.status].light} ${selectedNotificationId === item.id && notificationsStatusColors[item.status].text}`}>
              <div
                className={`w-2 h-2 ${item.status === 'pending' ? 'bg-blue-400' : item.status === 'sent' ? 'bg-green-500' : 'bg-slate-400'} rounded-3xl`} />
              <p className={'line-clamp-1 flex-1'}>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  return (
    <div className={'md:max-h-[calc(100vh-50px)] md:overflow-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-slate-200'}>
      <Calendar className={'min-w-[700px] mt-[120px] md:mt-0 border-r md:max-h-full'} fullCellRender={cellRender} headerRender={CalendarHeader}
                style={{ backgroundColor: 'white' }} />
    </div>
    );
};
export default NotificationsCalendar;


function CalendarHeader({ value, type, onChange, onTypeChange }: {
  value: Dayjs,
  type: 'month' | 'year',
  onChange: (value: Dayjs) => void,
  onTypeChange: (type: 'month' | 'year') => void
}) {


  const year = value.year();
  const month = value.month();
  const options = [];
  for (let i = 2023; i < year + 1; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }

  return (

      <div
        className={'flex items-center justify-between h-[50px] p-3 w-full border-b-[1px] border-b-slate-200 mt-[50px] max-w-[100vw] fixed z-[999] top-[45px] mb-[50px] bg-white md:flex md:static md:mt-0'}>
        <p className={'text-lg font-semibold capitalize'}>
          {value.locale('it').format('MMMM')} {value.year()}
        </p>
        <div className={'flex gap-2'}>
          <Button className={'w-8 h-8 rounded-3xl'} onClick={() => onChange(value.subtract(1, 'month'))}>
            <CaretLeftOutlined className={'mr-0.5'} />
          </Button>
          <Button className={'w-8 h-8 rounded-3xl'} onClick={() => onChange(value.add(1, 'month'))}>
            <CaretRightOutlined className={'ml-0.5'} />
          </Button>
        </div>
      </div>

  );
}
