import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';
import { apiV1 } from './apiClient';
import {
  Category,
  CheckoutData,
  CustomerForAnalytics, Invoice,
  Notification, PaginatedTransactions,
  SalesPointPreview, ShopStaff, SubscriptionAttrs,
  Transaction
} from '@next-is-free-monorepo/next-is-free-types';
import dayjs from 'dayjs';

export function salesPointsQueryOptions() {
  return queryOptions({
    queryKey: ['sales-points'],
    queryFn: async () => await apiV1.get<SalesPointPreview[]>('/shop/sales-points'),
    staleTime: Infinity
  });
}

export function customersQueryOptions(from_date: dayjs.Dayjs, to_date: dayjs.Dayjs) {
  return queryOptions({
    queryKey: ['customers', { from_date, to_date }],
    queryFn: async () => {
      /*await new Promise(resolve => setTimeout(resolve, 2000));*/
      return await apiV1.get<CustomerForAnalytics[]>(`/shop/customers?from_date=${from_date.toISOString()}&to_date=${to_date.toISOString()}`);
    },
    staleTime: 1000 * 60 * 60 * 24
  });
}

export function customersCountQueryOptions() {
  return queryOptions({
    queryKey: ['customers', 'count'],
    queryFn: async () => {
      /*await new Promise(resolve => setTimeout(resolve, 2000));*/
      return await apiV1.get<number>(`/shop/customers/count`);
    },
    staleTime: 1000 * 60 * 60 * 24
  });
}

export function transactionsQueryOptions(from_date: dayjs.Dayjs, to_date: dayjs.Dayjs, sales_point_id?: string) {
  return queryOptions({
    queryKey: ['transactions', { from_date, to_date, sales_point_id }],
    queryFn: async () => await apiV1.get<Transaction[]>(`/shop/transactions?from_date=${from_date.endOf('day').toISOString()}&to_date=${to_date.toISOString()}${sales_point_id ? `&sales_point_id=${sales_point_id}` : ''}`),
    staleTime: 1000 * 60 * 60 * 24
  });
}

export function paginatedTransactionsQueryOptions(from_date: dayjs.Dayjs | undefined, sales_point_id?: string, staff_member_id?: string) {
  return infiniteQueryOptions({
    queryKey: ['transactions', 'paginated', { from_date, sales_point_id, staff_member_id }],
    queryFn: async ({ pageParam }) => await apiV1.get<PaginatedTransactions>(`/shop/transactions/paginated?${from_date ? 'from_date=' + from_date.endOf('day').toISOString() : ''}${sales_point_id ? `&sales_point_id=${sales_point_id}` : ''}${staff_member_id ? `&staff_member_id=${staff_member_id}` : ''}&page=${pageParam}`),
    getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
    initialPageParam: 1
  });
}

export function notificationsQueryOptions() {
  return queryOptions({
    queryKey: ['notifications'],
    queryFn: async () => await apiV1.get<Notification[]>('/shop/notifications'),
    staleTime: 1000 * 60 * 60 * 24
  });
}

export function notificationsCountQueryOptions() {
  return queryOptions({
    queryKey: ['notifications', 'count'],
    queryFn: async () => await apiV1.get<number>('/shop/notifications/count'),
    staleTime: 1000 * 60 * 60 * 24
  });
}

export function readNotificationsQueryOptions(from_date: dayjs.Dayjs, to_date: dayjs.Dayjs) {
  return queryOptions({
    queryKey: ['read-notifications', { from_date, to_date }],
    queryFn: async () => await apiV1.get<{
      notification_id: string,
      created_at: string,
      title: string
    }[]>(`/shop/notifications/read?from_date=${from_date.toISOString()}&to_date=${to_date.toISOString()}`),
    staleTime: 1000 * 60 * 60 * 24
  });
}


export function subscriptionQueryOptions() {
  return queryOptions({
    queryKey: ['subscription'],
    queryFn: async () => await apiV1.get<SubscriptionAttrs>('/shop/subscription'),
    staleTime: 1000 * 60 * 60 * 24
  });
}

export function invoicesQueryOptions(enabled = true) {
  return queryOptions({
    queryKey: ['invoices'],
    queryFn: async () => await apiV1.get<Invoice[]>('/shop/invoices'),
    staleTime: 1000 * 60 * 60 * 24,
    enabled
  });
}

export function checkoutQueryOptions(enabled = true) {
  return queryOptions({
    queryKey: ['checkout'],
    queryFn: async () => await apiV1.get<CheckoutData>('/shop/checkout'),
    staleTime: 0,
    enabled
  });
}

export function categoriesQueryOptions() {
  return queryOptions({
    queryKey: ['categories'],
    queryFn: async () => await apiV1.get<Category[]>('/categories'),
    staleTime: Infinity
  });
}

export function staffQueryOptions(props?: { withArchived?: boolean }) {
  return queryOptions({
    queryKey: ['staff', props?.withArchived ? 'withArchived' : ''],
    queryFn: async () => await apiV1.get<ShopStaff[]>('/shop/staff' + (props?.withArchived ? `?withArchived=true`: '' )),
    staleTime: Infinity
  });
}
