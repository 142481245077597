import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { UIErrorI18nKey } from '@next-is-free-monorepo/i18n';
import { SupabaseClient } from '@supabase/supabase-js';
import { SuccessResponse } from '@next-is-free-monorepo/next-is-free-types';

type Options = { disableDefaultUiError?: boolean, isPublic?: boolean }

export class Api {
  private readonly baseUrl;
  private readonly onUiError;
  public supabase: SupabaseClient;

  constructor(version: number, apiUrl: string, onUiError: (error: UIErrorI18nKey) => void, supabaseClient: SupabaseClient) {
    this.baseUrl = apiUrl + `/v${version}`;
    this.onUiError = onUiError;
    this.supabase = supabaseClient;
  }

  private async getAccessToken(): Promise<string> {
    const { data, error } = await this.supabase.auth.getSession();
    if (error) throw error;

    const token = data?.session?.access_token;
    if (token) return token;

    await this.supabase.auth.signOut({scope: 'local'});
    window.location.reload();
    throw new Error('Access token not found. Signed out.');
  }

  private handleError(e: AxiosError, disableDefaultUiError?: boolean): void {
    const uiErrorMessage = e.response?.data as UIErrorI18nKey;
    if (uiErrorMessage && disableDefaultUiError !== true) {
      this.onUiError(uiErrorMessage);
    }
  }

  private async request(method: 'post' | 'put' | 'delete' | 'get', url: string, body?: object, options?: Options): Promise<any> {
    try {
      let headers;
      if (!options?.isPublic) {
        const accessToken = await this.getAccessToken();
        headers = { Authorization: 'Bearer ' + accessToken };
      }
      const response = await axios({ method, url: `${this.baseUrl}${url}`, data: body, headers });
      return response.data;
    } catch (e: unknown) {
      Sentry.captureException(e, { extra: { url, body, options } });
      if (e instanceof AxiosError) {
        this.handleError(e, options?.disableDefaultUiError);
      }
      throw e;
    }
  }

  public post<T = SuccessResponse>(url: string, body: object, options?: Options): Promise<T> {
    return this.request('post', url, body, options);
  }

  public put<T = SuccessResponse>(url: string, body: object, options?: Options): Promise<T> {
    return this.request('put', url, body, options);
  }

  public delete<T = SuccessResponse>(url: string, options?: Options): Promise<T> {
    return this.request('delete', url, undefined, options);
  }

  public get<T = SuccessResponse>(url: string, options?: Options): Promise<T> {
    return this.request('get', url, undefined, options);
  }
}
