import {z} from "zod";

export const TransactionSchema = z.object({
  id: z.string(),
  user_id: z.string().uuid(),
  shop_id: z.string().uuid(),
  sales_point_id: z.string().uuid(),
  sales_point_name: z.string(),
  points: z.number(),
  type: z.number(),
  created_at: z.string(),
  euro: z.number(),
  goal_name: z.string().nullable(),
  one_time_promo_id: z.string().uuid().nullable(),
  cancelled: z.boolean(),
  cancelled_at: z.string().nullable(),
})

export type Transaction = z.infer<typeof TransactionSchema>

export const PaginatedTransactionsSchema = z.object({
  transactions: z.array(TransactionSchema),
  hasMore: z.boolean(),
  nextPage: z.number().nullable()
})

export type PaginatedTransactions = z.infer<typeof PaginatedTransactionsSchema>

export const AddPointsSchema = z.object({
  amount: z.number().min(0.10),
  user_id: z.string().uuid(),
  sales_point_id: z.string().uuid(),
})

export type AddPointType = z.infer<typeof AddPointsSchema>

export const RedeemPointsSchema = z.object({
  qr_code: z.string(),
  sales_point_id: z.string().uuid()
})
