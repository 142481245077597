import ContainerBlock from './ContainerBlock';
import SingleLineChartTimeFormat from './charts/SingleLineChartTimeFormat';

import { groupDatesBy, sumEuros, sumPoints, sumYValues } from '../../utils/analytics';
import { endDate, startingDate, timeSpan } from '../../src/routes/_authenticated/analytics';
import { useSuspenseQuery } from '@tanstack/react-query';
import { transactionsQueryOptions } from '../../utils/queryOptions';
import { Select } from 'antd';
import { useState } from 'react';

const AllocatedPointsTrend = ({ selectedSalesPointId }: { selectedSalesPointId: string }) => {


  const [selected, setSelected] = useState<'points' | 'euros'>('points');

  const transactions = useSuspenseQuery(transactionsQueryOptions(endDate.value, startingDate.value, selectedSalesPointId));
  const groupedByDate = groupDatesBy(transactions.data, timeSpan.value);
  const summedPoints = sumPoints(groupedByDate, 0, timeSpan.value);
  const summedEuro = sumEuros(groupedByDate, 0, timeSpan.value);

  return (
    <ContainerBlock customRightElement={<Select size={'small'} style={{ width: 100 }}
                                                options={[{ label: 'Punti', value: 'points' }, {
                                                  label: 'Euro',
                                                  value: 'euros'
                                                }]} value={selected} onChange={(value: any) => setSelected(value)} />}
                    total={sumYValues(selected === 'points' ? summedPoints : summedEuro)}
                    title={selected === 'points' ? 'Punti assegnati a clienti' : 'Euro spesi dai clienti'}>
      <SingleLineChartTimeFormat data={selected === 'points' ? summedPoints : summedEuro} />
    </ContainerBlock>
  );
};
export default AllocatedPointsTrend;
