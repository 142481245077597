type StepFeedbackBadge = { tooltip?: string, color: 'green' | 'yellow' | 'red' }

export interface StepsFeedbackBadgeProps {
  steps: StepFeedbackBadge[];
  mode: 'progress' | 'single';
  selectedStep: number;
}

const StepsFeedbackBadge = (props: StepsFeedbackBadgeProps) => {

  const colors = {
    green: 'bg-green-500',
    yellow: 'bg-yellow-500',
    red: 'bg-red-500'
  };

  return (
    <div className={'flex'} style={{ gap: 2 }}>
      {
        props.steps.map((step, index) => {

          let color = 'bg-slate-200';

          if (props.mode === 'progress') {
            if (index < props.selectedStep) {
              color = colors[props.steps[props.selectedStep - 1].color];
            }
          }

          if(props.mode === 'single') {
            if (index === props.selectedStep - 1) {
              color = colors[step.color];
            }
          }

          return (
            <div key={index} style={{ height: 16, width: 6, borderRadius: 20 }} className={color} />
          );
        })
      }
    </div>
  );
};
export default StepsFeedbackBadge;
