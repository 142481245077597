import { Button, Modal } from 'antd';
import { IconWalk } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import BgIcon from '../microcomponents/BgIcon';


const CModal = ({
                  open,
                  onClose,
                  color = 'black',
                  icon,
                  title,
                  text,
                  children,
                  confirmButtonText = 'Conferma',
                  mutationOptions,
                  customFooter,
                  hideConfirmButton = false,
                  cancelButtonText = 'Annulla'
                }: {
  open: boolean,
  onClose: () => void,
  color?: 'blue' | 'green' | 'red' | 'black',
  icon: ReactNode,
  title?: string,
  text?: string,
  children?: ReactNode,
  confirmButtonText?: string,
  mutationOptions: UseMutationOptions<any>,
  customFooter?: ReactNode,
  hideConfirmButton?: boolean,
  cancelButtonText?: string
}) => {

  const mutation = useMutation(mutationOptions);

  const colors = {
    blue: { main: 'bg-indigo-700', hover: 'hover:bg-indigo-700', light: 'bg-indigo-100', text: 'text-indigo-600' },
    red: { main: 'bg-red-600', hover: 'hover:bg-red-700', light: 'bg-red-100', text: 'text-red-600' },
    green: { main: 'bg-green-700', hover: 'hover:bg-green-700', light: 'bg-green-100', text: 'text-green-600' },
    black: { main: 'bg-gray-900', hover: 'hover:bg-gray-700', light: 'bg-gray-100', text: 'text-gray-800' }
  };

  return (
    <Modal centered styles={{ content: { padding: 15, paddingTop: 8, paddingBottom: 1 } }} width={350} title={<div />}
           closeIcon={false} open={open} onCancel={onClose} footer={<div />}>
      <BgIcon icon={icon} color={color} />
      <div className={'mt-3'}>
        <p className={'font-semibold'}>{title}</p>
        {children}
        <p className={'text-sm text-gray-500'}>{text}</p>
      </div>
      {customFooter ? customFooter : <div className={'mt-6 flex justify-end gap-2'}>
        <Button loading={mutation.isPending} onClick={onClose}>{cancelButtonText}</Button>
        {!hideConfirmButton &&
          <Button loading={mutation.isPending} className={`${colors[color].main}`} onClick={() => mutation.mutate()}
                  type={'primary'}>{confirmButtonText}</Button>
        }
      </div>}
    </Modal>
  );
};
export default CModal;
