import { Suspense } from 'react';
import Wrapper from '../../Wrapper';
import CustomersNumberTrend from '../CustomersNumberTrend';
import AllocatedPointsTrend from '../AllocatedPointsTrend';
import MainActivityHours from '../MainActivityHours';
import GoalsRank from '../GoalsRank';
import NotificationsReadTime from '../NotificationsReadTime';
import MostReadNotifications from '../MostReadNotifications';

interface LoyaltyProgramTabProps {
}

const NotificationsTab = ({selectedSalesPointId}: {selectedSalesPointId: string}) => {
  return (
    <div className={'grid-cols-1 grid gap-y-[50px] gap-x-7 lg:grid-cols-2'}>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <NotificationsReadTime />
      </Suspense>
      <Suspense fallback={<Wrapper loading mh={460} />}>
        <MostReadNotifications />
      </Suspense>
    </div>
  );
};
export default NotificationsTab;
