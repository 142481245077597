export const COLORS = {
  white: 'white',
  veryLightGray: '#f7f7f7',
  lightGray: '#F1F5F9',
  mediumGray: '#d8d8d8',
  gray: '#64748B',
  darkBg: '#373B44',
  black: '#101921',
  red: '#F22626',
  primary: '#FFc600',
  primaryLight: '#FFE899',
  businessColor: '#0F1224',
  businessAppBg: '#00061A',
  businessAppBgLight: '#0D1326',
  businessPrimary: '#90A6F0',
  'gray-1': '#BDC4DB',
  'gray-2': '#D7DCE9',
  'gray-3': '#EEF0F6',
  'gray-text': '#6D7078',
  oneTimePromoBg: '#BFDBFE',
  oneTimePromoBgText: '#1D4ED8',
  savedBg: '#BBF7D0',
  savedBgText: '#16A34A',
  slate: {
    '50': '#f8fafc',
    '100': '#f1f5f9',
    '200': '#e2e8f0',
    '300': '#cbd5e1',
    '400': '#94a3b8',
    '500': '#64748b',
    '600': '#475569',
    '700': '#334155',
    '800': '#1e293b',
    '900': '#0f172a',
    '950': '#020617'
  }
}
