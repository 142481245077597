import React from 'react';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import * as Sentry from "@sentry/react";
import './App.css';

// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
const queryClient = new QueryClient();

// Create a new router instance
const router = createRouter({
  routeTree, context: { /*useShopAttrs: undefined!,*/ queryClient }, defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0
});

//Track page change
router.subscribe('onResolved', (evt) => {
  posthog.capture('$pageview', { '$current_url': evt.toLocation.pathname, 'fromLocation': evt.fromLocation.pathname });
  console.log(evt)
})


Sentry.init({
  dsn: "https://7328ce4e99acc19e2f3e6019f3afe937@o4506761687334912.ingest.us.sentry.io/4506773572616192",
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],

  // Setting a sample rate is required for sending performance data.
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control.
  tracesSampleRate: 1.0,
});


posthog.register({'platform': 'Dashboard Business'});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function Provider() {
  /*const shopAttrs = useShopAttrs();*/
  return( <RouterProvider router={router} context={{ /*useShopAttrs: shopAttrs,*/ queryClient }} />)
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider/>
    </QueryClientProvider>
  );
}
