import { bucketUrl } from '../utils/urls';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import useShopAttrs from '../utils/hooks/useShopAttrs';
import {
  CallToActionType,
  ctaLinkPrefixes,
  NotificationStatus,
  NotificationWidget
} from '@next-is-free-monorepo/next-is-free-types';
import { COLORS } from '@next-is-free-monorepo/globals';
import {
  CalendarOutlined,
  FacebookOutlined, InstagramOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import { ReactNode } from 'react';
import NotificationPreviewWidgetsSwitch from './notifications/NotificationPreviewWidgetSwitch';

const NotificationPreview = ({
                               scale = 1.2,
                               text,
                               title,
                               imageUrl,
                               ctaLabel,
                               ctaType,
                               ctaValue,
                               widgets,
                               notificationStatus
                             }: {
  scale?: number,
  text: string | undefined,
  title: string | undefined,
  imageUrl: string,
  ctaLabel: string | undefined,
  ctaType: CallToActionType | 'no-cta',
  ctaValue: string,
  widgets: NotificationWidget[] | undefined,
  notificationStatus: NotificationStatus
}) => {

  const shopAttrs = useShopAttrs();

  const ctaIcons: { [type in CallToActionType]: ReactNode } = {
    email: <MailOutlined />,
    call: <PhoneOutlined />,
    url: <LinkOutlined />,
    whatsapp: <WhatsAppOutlined />,
    facebook: <FacebookOutlined />,
    instagram: <InstagramOutlined />
  };

  const defaultImageUrl = `${bucketUrl}${shopAttrs.data.id}/logo.png?${shopAttrs.data.latest_update_at}`;
  return (
    <>
      {imageUrl ? (
        <div
          style={{
            backgroundColor: COLORS.lightGray,
            width: '100%',
            aspectRatio: 1.4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            draggable={false}
            alt={'notification image'}
            style={{ width: '100%', maxHeight: '100%', height: '100%', objectFit: 'cover' }}
            src={imageUrl}
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: COLORS.lightGray,
            width: '100%', aspectRatio: 1.4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            draggable={false}
            alt={'default image'}
            style={{ width: '100%', maxHeight: '100%', objectFit: 'cover' }}
            src={defaultImageUrl}
          />
        </div>
      )}
      <div style={{ padding: 15 / scale, paddingTop: 10 / scale }}>
        {/*<Flex>
                    <img draggable={false} src={defaultImageUrl}
                         style={{width: 40 / scale, height: 40 / scale, borderRadius: 40}}
                         alt="notification image"/>
                    <p>{'asdas'}</p>
                </Flex>*/}
        {/*<p style={{
                    marginTop: 20 / scale,
                    fontSize: 12 / scale,
                    color: COLORS.gray
                }}>{new Date().toLocaleDateString()}</p>*/}
        <h3 className={'font-semibold'} style={{ color: title ? 'black' : 'grey', fontSize: 15 / scale }}>
          {title ? title : 'Titolo'}
        </h3>
        <div
          style={{
            userSelect: 'none',
            width: '100%',
            height: '100%',
            fontSize: 15 / scale,
            border: 'none',
            backgroundColor: 'white',
            color: text ? 'black' : 'grey',
            marginTop: 10 / scale
          }}
        >
          {text &&
            <HighlightWithinTextarea
              value={text}
              placeholder={'Testo'}
              readOnly={true}
              highlight={[
                {
                  highlight: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                  className: 'notification-url'
                }
              ]}
            />
          }
        </div>
        {widgets?.map((widget, index) => (
          <NotificationPreviewWidgetsSwitch notificationStatus={notificationStatus} key={index}
                                            scale={scale} {...widget} />))}
        {ctaType !== 'no-cta' &&
          <a href={ctaLinkPrefixes[ctaType] + ctaValue} target={'_blank'} style={{
            padding: 12,
            backgroundColor: 'black',
            borderRadius: 30,
            justifyContent: 'center',
            display: 'flex',
            marginTop: 20,
            color: 'white',
            gap: 8,
            alignItems: 'center'
          }}>
            {ctaIcons[ctaType]}
            <p style={{ color: 'white' }}>
              {ctaLabel}
            </p>
          </a>
        }
      </div>

    </>
  );
};
export default NotificationPreview;
