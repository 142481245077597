import { Collapse } from 'antd';
import * as React from 'react';

interface SingleCollapseProps {
}

const SingleCollapse = ({ children, header }: { children: React.ReactNode, header: React.ReactNode | string }) => {
  return (
    <Collapse className={'mt-10'} ghost style={{ padding: 0 }} items={[{
      key: '1',
      label: header,
      children,
      classNames: {
        header: '',
        body: ''
      },
      styles: {
        header: {
          padding: 0,
        },
        body: {
          padding: 0
        }
      }
    }]}
              expandIconPosition={'end'}
    >
    </Collapse>);
};
export default SingleCollapse;
