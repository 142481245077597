import ContainerBlock from "./ContainerBlock";
import {
        countOccurrences,
    getDistinctGoalNames, groupDatesBy,
} from "../../utils/analytics";
import BarsChart from "./charts/BarsChart";

import { endDate, startingDate, timeSpan } from '../../src/routes/_authenticated/analytics';
import { useSuspenseQuery } from '@tanstack/react-query';
import { transactionsQueryOptions } from '../../utils/queryOptions';
import HorizontalRankBars from './charts/HorizontalRankBars';

const GoalsRankHours = ({ selectedSalesPointId }:{selectedSalesPointId: string} ) => {

  const transactions = useSuspenseQuery(transactionsQueryOptions(endDate.value, startingDate.value, selectedSalesPointId));

  const promotions = transactions.data.filter((item) => item.type === 1);

const groupedGoalsByName = promotions.reduce((acc, promotion) => {
  const goalName = promotion.goal_name ?? 'Unknown Goal';
  const existingGoal = acc.find(item => item.index === goalName);

  if (existingGoal) {
    existingGoal.value++;
  } else {
    acc.push({ index: goalName, value: 1, title: goalName, id: promotion.id });
  }

  return acc;
}, [] as Array<{ index: string, value: number, title: string, id: string }>);

// group promotions by goal name and count occurrences


  console.log(groupedGoalsByName);
  //return the goal names
  const goalNames = groupedGoalsByName.map((item)=> Object.keys(item)[0]);



  return (
    <ContainerBlock noData={groupedGoalsByName.length < 1} title={"Traguardi più usati"}>
      <div/>
     <HorizontalRankBars
        data={groupedGoalsByName}
      />
    </ContainerBlock>
  );
};
export default GoalsRankHours;
