import { useRef, useState } from 'react';
import { Flex, Skeleton } from 'antd';

import { MoreOutlined, StarOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import { COLORS } from '@next-is-free-monorepo/globals';

interface GoalBarPreviewProps {
  brandColor: string,
  goals: any[],
  imagePreviewUrl: string | undefined,
  businessName: string,
  businessCategory: string | undefined,
  highestNumber: number,
  initialPoints: number
}

const GoalBarPreview = ({
                          brandColor,
                          goals,
                          imagePreviewUrl,
                          businessName,
                          businessCategory,
                          highestNumber,
                          initialPoints
                        }: GoalBarPreviewProps) => {

  const [imageLoading, setImageLoading] = useState(true);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const elementRef = useRef<any>(null);

  const onMouseDown = (e: any) => {
    const div = elementRef.current;
    setIsDragging(true);
    setStartX(e.pageX - div.offsetLeft);
    setStartY(e.pageY - div.offsetTop);
    setScrollLeft(div.scrollLeft);
    setScrollTop(div.scrollTop);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e: any) => {
    if (!isDragging) return;
    const div = elementRef.current;
    const x = e.pageX - div.offsetLeft;
    const y = e.pageY - div.offsetTop;
    const walkX = (x - startX) * 1; // Adjust the multiplier for faster/slower scrolling
    const walkY = (y - startY) * 1; // Adjust the multiplier for faster/slower scrolling
    div.scrollLeft = scrollLeft - walkX;
    div.scrollTop = scrollTop - walkY;
  };

  const newShade = (hexColor: string, magnitude: number) => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  const lighterBrandColor = newShade(brandColor, 230);
  const scale = 1.2;

  return (
    <>
      <div style={{
        width: '100%',
        aspectRatio: 1.4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: brandColor,
        overflow: 'hidden',
        position: 'relative'
      }}>
        {imagePreviewUrl && (
          <>
            {imageLoading &&
              <div style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#f7f7f7',
                position: 'absolute'
              }}>
                <Skeleton.Node style={{ width: 400, height: 400 }} fullSize={true} active>
                  <div />
                </Skeleton.Node>
              </div>
            }
            <img draggable={false} onLoad={() => setImageLoading(false)} alt={'logo'}
                 style={{ objectFit: 'cover', width: '100%', aspectRatio: 1.4 }}
                 src={imagePreviewUrl} />
          </>
        )}
      </div>
      <div style={{
        paddingTop: 40 / scale,
        paddingBottom: 70 / scale
      }}>
        <div style={{
          paddingLeft: 15 / scale,
          paddingRight: 15 / scale,
          marginBottom: 40 / scale,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <p className={'previewBusinessName'} style={{ fontSize: 25 / scale }}>{businessName}</p>
          {businessCategory && <p>{businessCategory}</p>}
          {/* <div style={{padding: '3px 8px', backgroundColor: lighterBrandColor, borderRadius: 20, border: '1px solid', borderColor: brandColor}}>
                        <p style={{color: brandColor, fontWeight: 700, margin: 0, fontSize: width / 30}}>{initialPoints}/{highestNumber}</p>
                    </div>*/}
          <Flex gap={8 / scale}>
            <div style={{
              borderRadius: 20,
              height: 35 / scale,
              width: 35 / scale,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: COLORS.gray,
              color: COLORS.gray
            }}>
              <FeatherIcon size={16} icon={'more-horizontal'} />
            </div>
            <div style={{
              borderRadius: 20,
              height: 35 / scale,
              width: 35 / scale,
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: brandColor,
              display: 'flex',
              color: brandColor
            }}>
              <FeatherIcon size={16} icon={'bell'} />
            </div>
          </Flex>
        </div>
        <div style={{ height: '60%', gap: 10, width: '100%', marginTop: 20 / scale }}>
          <p style={{
            paddingRight: 15 / scale,
            textAlign: 'right',
            marginBottom: 10 / scale,
            fontWeight: 500,
            color: brandColor,
            fontSize: 18 / scale
          }}>{initialPoints + '/' + highestNumber}</p>
          <div style={{ paddingLeft: 15 / scale, paddingRight: 15 / scale }}>
            <div className="previewBarW"
                 style={{ width: '100%', height: 40 / scale, position: 'relative', overflow: 'hidden' }}>
              <div style={{
                backgroundColor: brandColor,
                width: initialPoints / highestNumber * 100 + '%',
                height: '100%',
                borderRadius: 200
              }}>
              </div>
              <div style={
                {
                  width: '100%',
                  height: 4,
                  position: 'absolute',
                  top: 'calc(50% - 7px)'
                }
              }>
                {goals.map((item, index) => {
                    const percentage = item.number / highestNumber * 100 + '%';
                    return (
                      <div key={item.title + index} style={{
                        width: 4,
                        height: 14,
                        borderRadius: 20,
                        backgroundColor: lighterBrandColor,
                        position: 'absolute',
                        left: percentage
                      }} />
                    );
                  }
                )}

              </div>
            </div>
          </div>
          <div className={'previewGoalCardsW'} onMouseDown={onMouseDown}
               onMouseLeave={onMouseLeave}
               onMouseUp={onMouseUp}
               onMouseMove={onMouseMove} ref={elementRef}
               style={{ position: 'relative', display: 'flex', gap: 10 / scale, overflowX: 'scroll' }}>
            {goals.map((item, index) => {
                return (
                  <div key={index} style={{
                    fontSize: 14 / scale,
                    padding: 8 / scale,
                    minWidth: 'calc(100% / 2.7)',
                    backgroundColor: item.number > initialPoints ? COLORS.lightGray : lighterBrandColor,
                    borderRadius: 16 / scale,
                    textAlign: 'center',
                    color: item.number > initialPoints ? COLORS.gray : brandColor,
                    aspectRatio: 0.90,
                    marginTop: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    borderColor: item.number > initialPoints ? COLORS.lightGray : brandColor,
                    position: 'relative',
                    marginLeft: index === 0 ? 15 / scale : 0,
                    marginRight: index === goals.length - 1 ? 15 / scale : 0
                  }}>
                    <div style={{
                      backgroundColor: item.number > initialPoints ? COLORS.gray : brandColor,
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                      position: 'absolute',
                      top: 5 / scale,
                      right: 5 / scale,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white'
                    }}>
                      {item.number > initialPoints ?
                        <img alt={'lock'} src={'/lock.svg'} /> :
                        <FeatherIcon icon={'star'} size={12} />
                      }
                    </div>
                    <div style={{
                      fontSize: 18,
                      fontWeight: 700
                    }}>
                      {item.number}
                    </div>
                    {item.title ?
                      <p style={{fontSize: 14, lineHeight: 1}}>{item.title}</p> :
                      <p><i>Nome premio</i></p>
                    }
                  </div>

                );
              }
            )}
          </div>
        </div>
      </div>
      <div style={{
        backgroundColor: brandColor,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60 / scale,
        paddingBottom: 60 / scale,
        display: 'flex',
        flexDirection: 'column',
        gap: 20 / scale
      }}>
        <div style={{ padding: 20 / scale, borderRadius: 20 / scale, backgroundColor: 'white' }}>
          <img draggable={false} alt={'dummy-qr-code'} width={240 / scale} src={'/dummy-qr-code.png'} />
        </div>
        <p style={{
          width: 280 / scale,
          textAlign: 'center',
          color: 'white',
          fontWeight: 500,
          fontSize: 16 / scale
        }}>Mostra il codice alla cassa per ottenere punti</p>
      </div>
    </>
  );
};
export default GoalBarPreview;
