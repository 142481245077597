import {Button} from "antd";
import {useState} from "react";
import {blue} from "@ant-design/colors";

interface ConfirmButtonProps {
    text: string,
    cancelText?: string,
    okText?: string,
    message?: string,
    onConfirm: ()=>void,
    loading: boolean
}

const ConfirmButton = ({text, cancelText = 'Annulla', okText = 'Ok', message = 'Vuoi continuare?', onConfirm, loading}: ConfirmButtonProps) => {
    const [open, setOpen] = useState(false);
    return (
        <div style={{width: '100%'}}>
            {open ? (
                <>
                    <div style={{padding: '20px', backgroundColor: blue[0], borderRadius: 10, border: '1px solid', borderColor: blue[1], marginBottom: 10}}>
                        <p style={{color: blue[4]}}><b>{message}</b></p>
                    </div>

                <div style={{width: '100%', display: 'flex', gap: 10}}>
                    <Button style={{width: '100%'}} onClick={()=>{setOpen(false)}} size={'large'} disabled={loading}>{cancelText}</Button>
                    <Button style={{width: '100%'}} type={'primary'} size={'large'} onClick={()=>{onConfirm(); setOpen(false)}} loading={loading} disabled={loading}>{okText}</Button></div></>
            ) : (
                <Button disabled={loading} loading={loading} style={{width: '100%'}} type={'primary'} size={'large'}
                        onClick={() => setOpen(true)}>{text}</Button>)}

        </div>);
}
export default ConfirmButton;
