import {Button, Flex, Input, message, Space} from "antd";
import { useEffect, useState } from 'react';
import {CloseOutlined} from "@ant-design/icons";
import {supabase} from "../../utils/supabase";
import Wrapper from "../Wrapper";


const ChangeEmail = () => {
    const [disabled, setDisabled] = useState(true);
    const [newEmail, setNewEmail] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      async function getCurrentEmail(){
        const user = await supabase.auth.getUser();
        if(user?.data?.user?.email){
          setNewEmail(user.data.user.email);
        }
      }
      getCurrentEmail();
    }, []);



    async function updatePassword() {
        setLoading(true);
        const {data, error} = await supabase.auth.updateUser({
            email: newEmail,
        });
        if (error) {
            message.error(error.message);
            setLoading(false);

            return;
        }
        if (data) {
            message.success("Abbiamo inviato un link di verifica all'indirizzo email specificato. Controlla la tua casella di posta elettronica per confermare il nuovo indirizzo email.");
            setDisabled(true);
            setLoading(false)
        }
    }

    return (
        <Wrapper disabled={disabled} p={20}>
            <h3 style={{marginBottom: 10}}>Cambia Email dell'account</h3>
            <Flex gap={6}>
                {disabled ?
                    <Input
                        style={{height: 33}}
                        value={newEmail}
                        disabled={true}
                        placeholder={"Nuova Email"}
                    /> :
                    <Input
                        style={{height: 33}}
                        value={newEmail}
                        placeholder={"Nuova Email"}
                        onChange={(e) => {
                            setNewEmail(e.target.value);
                        }}
                    />
                }
                {disabled ? (
                    <Button
                        type={'primary'}
                        style={{width: 95}}
                        onClick={() => {
                            setDisabled(false);
                            setNewEmail("");
                        }}
                    >
                        Cambia
                    </Button>
                ) : (
                    <Space>
                        <Button loading={loading} type={"primary"} onClick={updatePassword}>
                            Cambia
                        </Button>
                        <Button
                            onClick={() => {
                                setDisabled(true);
                            }}
                        >
                            <CloseOutlined/>
                        </Button>
                    </Space>
                )}
            </Flex>
        </Wrapper>
    );
};
export default ChangeEmail;
